/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {
    DedupeStrategy,
    NotificationGrouping,
    StatusChangeStrategy,
    WorkOrderConfiguration
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import {WorkOrderEquipmentType, WorkOrderType} from '@amzn/id4-mothership/com/amazon/id4notificationservice/model/types/apm';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Textarea from '@amzn/meridian/textarea';
import Toggle from '@amzn/meridian/toggle';
import React from 'react';

import {useSearchSelect} from '../use-search-select';

/**
 * Hook to provide a work order template.
 *
 * @returns {[WorkOrderConfiguration, () => void, (arg0: WorkOrderConfiguration) => void, boolean, React.ReactElement]} work order configuration, reset input function, set input function, input error or incomplete, work order template component
 */
const useWorkOrderTemplate = (): [WorkOrderConfiguration, () => void, (arg0: WorkOrderConfiguration) => void, boolean, React.ReactElement] => {

    const [problemResolver, setProblemResolver] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>(undefined);
    const [comment, setComment] = React.useState<string>(undefined);
    const [requester, setRequester] = React.useState<string>(undefined);
    const [workAllocationDays, setWorkAllocationDays] = React.useState<number>(0);

    const [workOrderType, setWorkOrderType, WorkOrderTypeSelect] = useSearchSelect({
        label: 'Work order type',
        placeholder: 'Select work order type',
        options: Object.entries(WorkOrderType).map(([label, value]) => ({ label: label, value: value })),
        isLoading: false,
        loadingMessage: 'Loading problem types...',
    });

    const [equipmentType, setEquipmentType, EquipmentTypeSelect] = useSearchSelect({
        label: 'Equipment type',
        placeholder: 'Select equipment type',
        options: Object.entries(WorkOrderEquipmentType).map(([label, value]) => ({ label: label, value: value })),
        isLoading: false,
        loadingMessage: 'Loading equipment types...',
    });

    const [notificationGrouping, setNotificationGrouping, NotificationGroupingSelect] = useSearchSelect({
        label: 'Notification grouping',
        placeholder: 'Select notification grouping option',
        options: Object.entries(NotificationGrouping).map(([label, value]) => ({ label, value })),
        isLoading: false,
        loadingMessage: 'Loading notification grouping options...',
    });

    const [dedupeStrategy, setDedupeStrategy, DedupeStrategySelect] = useSearchSelect({
        label: 'Deduplication strategy',
        placeholder: 'Select deduplication strategy',
        options: Object.entries(DedupeStrategy).map(([label, value]) => ({ label, value })),
        isLoading: false,
        loadingMessage: 'Loading strategies...',
    });

    const [expirationStrategy, setExpirationStrategy, ExpirationStrategy] = useSearchSelect({
        label: 'Expiration strategy',
        placeholder: 'Select expiration strategy',
        options: Object.entries(StatusChangeStrategy).map(([label, value]) => ({ label: label, value: value })),
        isLoading: false,
        loadingMessage: 'Loading strategies...',
    });

    const [closureStrategy, setClosureStrategy, ClosureStrategy] = useSearchSelect({
        label: 'Closure strategy',
        placeholder: 'Select closure strategy',
        options: Object.entries(StatusChangeStrategy).map(([label, value]) => ({ label: label, value: value })),
        isLoading: false,
        loadingMessage: 'Loading strategies...',
    });

    /**
     * Validates the inputs.
     *
     * @returns {boolean} true if all are valid
     */
    function areAllInputsValid(): boolean {
        return !!title
            && !!comment
            && !!requester
            && workAllocationDays >= 0
            && !!workOrderType
            && !!equipmentType
            && !!dedupeStrategy
            && !!closureStrategy
            && !!notificationGrouping
            && problemResolver !== undefined
            && !!expirationStrategy;
    }

    /**
     * Resets the inputs.
     */
    function resetInput() {
        setTitle(undefined);
        setComment(undefined);
        setRequester(undefined);
        setWorkAllocationDays(0);
        setWorkOrderType(undefined);
        setEquipmentType(undefined);
        setNotificationGrouping(undefined);
        setDedupeStrategy(undefined);
        setExpirationStrategy(undefined);
        setProblemResolver(false);
        setClosureStrategy(undefined);
    }

    /**
     * Sets the work order inputs with a given configuration.
     *
     * @param configuration work order configuration
     */
    function setWorkOrderInput(configuration: WorkOrderConfiguration) {
        if (configuration) {
            setTitle(configuration.titleTemplate);
            setComment(configuration.commentTemplate);
            setRequester(configuration.requester);
            setWorkAllocationDays(configuration.expectedWorkAllocationInDays);
            setWorkOrderType(configuration.workOrderType);
            setEquipmentType(configuration.equipmentType);
            setNotificationGrouping(configuration.notificationGrouping);
            setDedupeStrategy(configuration.dedupeStrategy);
            setExpirationStrategy(configuration.expirationStrategy);
            setClosureStrategy(configuration.closureStrategy);
            setProblemResolver(configuration.problemResolver);
        }
    }

    const WorkOrderTemplate = (
        <Column width='100%'>
            <Toggle checked={problemResolver} onChange={setProblemResolver}>Enable problem resolution?</Toggle>
            <Input
                id='work-order-title'
                value={title}
                onChange={setTitle}
                type='text'
                label='Title'
                error={title === ''}
                errorMessage={title === '' && 'Title cannot be empty'}
            />
            <Textarea
                value={comment}
                onChange={setComment}
                placeholder={'Enter work order comment...'}
                label={'Comment'}
                error={comment === ''}
                errorMessage={comment === '' && 'Comment cannot be empty'}
            />
            <Input
                id='work-order-requester'
                value={requester}
                onChange={setRequester}
                type='text'
                label='Requester'
                error={requester === ''}
                errorMessage={requester === '' && 'Requester cannot be empty'}
            />
            {WorkOrderTypeSelect}
            {EquipmentTypeSelect}
            <Input
                id='work-order-allocation-days'
                value={workAllocationDays}
                onChange={setWorkAllocationDays}
                type='number'
                label='Expected work allocation'
                suffix='days'
                width='50%'
                error={workAllocationDays < 0}
                errorMessage={workAllocationDays < 0 && 'Expected work allocation days cannot be less than 0'}
            />
            {NotificationGroupingSelect}
            {DedupeStrategySelect}
            {ExpirationStrategy}
            {ClosureStrategy}
        </Column>
    );

    return [
        {
            titleTemplate: title,
            commentTemplate: comment,
            requester: requester,
            workOrderType: workOrderType as WorkOrderType,
            equipmentType: equipmentType as WorkOrderEquipmentType,
            expectedWorkAllocationInDays: Number(workAllocationDays),
            expirationStrategy: expirationStrategy as StatusChangeStrategy,
            closureStrategy: closureStrategy as StatusChangeStrategy,
            notificationGrouping: notificationGrouping as NotificationGrouping,
            dedupeStrategy: dedupeStrategy as DedupeStrategy,
            problemResolver: problemResolver,
            // TODO: what are the escalation severities for WOs? Same as tickets?
            escalationMap: {}
        },
        resetInput,
        setWorkOrderInput,
        !areAllInputsValid(),
        WorkOrderTemplate
    ];
};

export default useWorkOrderTemplate;
