/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Menu, {MenuItem} from '@amzn/meridian/menu';
import geopinTokens from '@amzn/meridian-tokens/base/icon/geopin';
import {useBundle} from '@amzn/react-arb-tools';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {selectSelectedRegion, setSelectedRegion, SupportedRegions} from '../../../state/app/appSlice';
import {LocalStorageKeys, setLocalStorageItem} from '../../../utility/local-storage';

type RegionOption = { label: string, value: SupportedRegions, ariaLabel: string };

const RegionMenu = () => {

    const [bundle] = useBundle('RegionMenu.RegionMenu');

    const dispatch = useDispatch();

    const selectedRegion = useSelector(selectSelectedRegion);

    /**
     * Button ref.
     */
    const buttonRef = React.useRef();

    /**
     * React state for if the menu is open/closed.
     */
    const [open, setOpen] = React.useState(false);

    /**
     * On menu button click handler.
     */
    const onClickButton = React.useCallback(() => setOpen(!open), []);

    /**
     * On menu button close handler.
     */
    const onClose = React.useCallback(() => setOpen(false), []);

    if (bundle === undefined) {
        return null;
    }

    const NA_REGION_LABEL = bundle.getMessage('north-america');
    const EU_REGION_LABEL = bundle.getMessage('europe');
    const AP_REGION_LABEL = bundle.getMessage('asia-pacific');
    const INTEG_REGION_LABEL = bundle.getMessage('integration');

    /**
     * Creates the lists of regions for the drop-down based on the hostname URL.
     *
     * @returns {RegionOption[]} region option list
     */
    function createRegionListBasedOnHostname(): RegionOption[] {
        if (window.location.hostname.includes('prod')) {
            return [
                {label: NA_REGION_LABEL, value: 'NA', ariaLabel: 'na-prod'},
                {label: EU_REGION_LABEL, value: 'EU', ariaLabel: 'eu-prod'},
                {label: AP_REGION_LABEL, value: 'AP', ariaLabel: 'ap-prod'},
            ];
        } else if (window.location.hostname.includes('gamma')) {
            return [
                {label: NA_REGION_LABEL, value: 'NA', ariaLabel: 'na-gamma'},
            ];
        } else {
            return [
                {label: INTEG_REGION_LABEL, value: 'Integ', ariaLabel: 'beta'}
            ];
        }
    }

    /**
     * Gets the region text.
     *
     * @returns {string} renderable region
     */
    function getRegionText() {
        switch (selectedRegion) {
            case 'NA': {
                return NA_REGION_LABEL;
            }
            case 'EU': {
                return EU_REGION_LABEL;
            }
            case 'AP': {
                return AP_REGION_LABEL;
            }
            case 'Integ': {
                return INTEG_REGION_LABEL;
            }
        }
    }

    const setChosenRegion = (option: RegionOption) => {
        // Store the region in local storage so it can be retrieved next time the page loads
        setLocalStorageItem(LocalStorageKeys.ID4_REGION, option.value);
        dispatch(setSelectedRegion(option.value));
    };

    return (
        <React.Fragment>
            <Button type='icon' size='small' onClick={onClickButton} ref={buttonRef} data-testid='region-select'>
                <Icon tokens={geopinTokens}>Region</Icon>{getRegionText()}
            </Button>
            <Menu anchorNode={buttonRef.current} open={open} position='bottom' onClose={onClose} minWidth={175}>
                {
                    createRegionListBasedOnHostname().map(region => {
                        return (
                            <MenuItem
                                key={`region-menu-item-${region.label}`}
                                onClick={() => setChosenRegion(region)}
                                aria-label={region.ariaLabel}
                            >
                                {region.label}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </React.Fragment>
    );
};

export default RegionMenu;
