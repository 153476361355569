/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Button from '@amzn/meridian/button';
import Loader from '@amzn/meridian/loader';
import React from 'react';

export type LoadingButtonProps = {
    text: string
    isLoading: boolean
    disabled: boolean
    onClick?: () => void
    submit?: boolean
};

const LoadingButton = ({
    text,
    isLoading,
    disabled,
    onClick,
    submit
}: LoadingButtonProps) => {

    return (
        <Button disabled={disabled} onClick={onClick} submit={submit}>
            { isLoading && <Loader size='small' /> }{text}
        </Button>
    );
};

export default LoadingButton;
