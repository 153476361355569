/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Select, {SelectOption} from '@amzn/meridian/select';
import Tag from '@amzn/meridian/tag';
import React, {useState} from 'react';

export interface MapPair {
    key: string;
    value: string;
}

interface MapEditorProps {
    initialJson: string | object;
    options: string[];
    setDetails: (val: any) => void
}

const CodeEditorStyle: React.CSSProperties = {
    backgroundColor: '#2d2d2d',   // Dark background like VS Code
    color: '#f8f8f2',             // Light text color
    fontFamily: '"Fira Code", monospace', // Monospaced font for code
    fontSize: '14px',              // Standard code editor font size
    padding: '16px',               // Padding inside the code block
    borderRadius: '5px',           // Rounded corners
    border: '1px solid #444',      // Border to mimic code editor container
    overflow: 'scroll',            // Always show scrollbars
    whiteSpace: 'pre-wrap',        // Allows line breaks
    lineHeight: '1.5',             // Better readability
    maxWidth: '100%',              // Prevents the box from growing wider than its container
    // maxHeight: '100px',            // Sets a max height, scrolls if content exceeds
    minHeight: '100px',                 // Allows auto-sizing until max width is reached
    height: 'auto',                // Same for height, but restricted by maxHeight
};

const TagStyle: React.CSSProperties = {
    cursor: 'pointer'
};

const MapEditor: React.FC<MapEditorProps> = ({initialJson, options, setDetails}) => {
    const [mapPairs, setMapPairs] = useState<MapPair[]>(() => {
        try {
            let mapPairs: MapPair[] = [];
            let parsed = {};
            if (typeof initialJson === 'string') {
                parsed = JSON.parse(initialJson);
            } else {
                parsed = initialJson;
            }
            Object.entries(parsed).forEach((entry, value) => {
                mapPairs.push({key: entry[0], value: entry[1].toString()});
            });
            return mapPairs;
        } catch (error) {
            console.error(error);
            return [];
        }
    });

    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState(options[0]);

    const handleAddMapPair = () => {
        let keys = mapPairs.map(mp => mp.key);
        if (!!newKey && !keys.includes(newKey)) {
            setMapPairs([...mapPairs, {key: newKey, value: newValue}]);
            setDetails([...mapPairs, {key: newKey, value: newValue}]);
            setNewKey('');
            setNewValue(options[0]);
        }
    };

    const handleDeleteMapPair = (index: number) => {
        const updatedPairs = mapPairs.filter((_, i) => i !== index);
        setMapPairs(updatedPairs);
        setDetails(updatedPairs);
    };

    const getJsonString = () => {
        const obj = mapPairs.reduce((acc, pair) => {
            acc[pair.key] = pair.value;
            return acc;
        }, {} as { [key: string]: string });
        return JSON.stringify(obj, null, 2);
    };

    return (
        <Row>
            <Column className={'key-data-restrictions-input'} alignmentVertical={'top'} width={'25%'}>
                <Input
                    size={'small'}
                    type='text'
                    value={newKey}
                    onChange={(e) => setNewKey(e)}
                    placeholder='New Key'
                />
                <Select
                    size={'small'}
                    value={newValue}
                    onChange={(e) => setNewValue(e)}
                >
                    {options.map(option => (
                        <SelectOption label={option} key={option} value={option}/>
                    ))}
                </Select>
                <Button disabled={(!newKey || !newValue)} onClick={handleAddMapPair}>Add Restriction</Button>
            </Column>
            <Column className={'key-data-restrictions-input'} width={'75%'}>
                <pre style={CodeEditorStyle}>{getJsonString()}</pre>
                <Row overflowX={'auto'} wrap={'down'}>
                    {mapPairs.map((pair, index) => (
                        <div key={index} style={{marginBottom: '10px'}}>
                            <div>
                                <Column spacingInset={'100'}>
                                    <Row>
                                        <div style={TagStyle}>
                                            <Tag onClose={() => handleDeleteMapPair(index)}>
                                                {pair.key}
                                            </Tag>
                                        </div>
                                    </Row>
                                </Column>
                            </div>
                        </div>
                    ))}
                </Row>
            </Column>
        </Row>
    );
};

export default MapEditor;
