/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {WebhookRegistrationSet} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/scheduler';
import {NavigateFunction} from 'react-router/dist/lib/hooks';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {
    deleteWebhookSet,
    getWebhookSet,
    listWebhooks,
    listWebhookSets,
    putWebhookSet
} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {IDeleteResource} from '../IDeleteResource';
import {EditResourceComponentType, EditResourceConfiguration, IPutResource} from '../IPutResource';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../ITableResource';
import {IViewResource, ViewResourceComponentType, ViewResourceConfiguration} from '../IViewResource';


/**
 * Webhook set resource config.
 */
export class WebhookSetResourceConfig implements IPutResource<WebhookRegistrationSet>, IViewResource<WebhookRegistrationSet>, ITableResource<WebhookRegistrationSet>, IDeleteResource<WebhookRegistrationSet> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<WebhookRegistrationSet[]> {
        return await listWebhookSets(webStageConfig, selectedRegion);
    }


    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<WebhookRegistrationSet> {
        return getWebhookSet(webStageConfig, region, {webhookRegistrationSetId: resourceId});
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: WebhookRegistrationSet) {
        await deleteWebhookSet(webStageConfig, region, {webhookRegistrationSetId: resource.webhookRegistrationSetId});
    }

    async putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: WebhookRegistrationSet): Promise<WebhookRegistrationSet> {
        return await putWebhookSet(webStageConfig, region,
            {
                webhookRegistrationSetId: resource.webhookRegistrationSetId,
                webhookRegistrationSetDetails: {...resource}
            }
        ).then(response => response.webhookRegistrationSet);
    }

    navigateCreateResource(): void {
        this.navigate(`${RouteName.WEBHOOK_SETS}/create`);
    }

    navigateEditResource(resource: WebhookRegistrationSet): void {
        this.navigate(`${RouteName.WEBHOOK_SETS}/${resource.webhookRegistrationSetId}/edit`);
    }

    navigateViewResource(resource: WebhookRegistrationSet): void {
        this.navigate(`${RouteName.WEBHOOK_SETS}/${resource.webhookRegistrationSetId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.WEBHOOK_SETS);
    }

    resourceName(): string {
        return 'Webhook Set';
    }

    initializeDefaultObject(): WebhookRegistrationSet {
        return {
            webhookRegistrationSetId: undefined,
            ownerCti: {
                category: 'Amazon Robotics',
                type: 'GSS',
                item: 'Tools-Dev',
            },
            description: undefined,
            webhookRegistrationIds: [],
            tags: []
        };
    }

    async editResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, resource: WebhookRegistrationSet): Promise<Record<string, EditResourceConfiguration>> {
        let webhookSets = await listWebhookSets(webStageConfig, selectedRegion);
        let webhooks = await listWebhooks(webStageConfig, selectedRegion);
        return {
            webhookRegistrationSetId: {
                isIdentifier: true,
                editable: true,
                helperText: 'The unique identifier for your webhook set. Example: WebhookSetNameX',
                label: 'Webhook Set ID *',
                validationRegex: new RegExp('[A-Z]([A-Z0-9]*[a-z][a-z0-9]*[A-Z]|[a-z0-9]*[A-Z][A-Z0-9]*[a-z])[A-Za-z0-9]*'),
                disallowedValues: webhookSets.map(v => v.webhookRegistrationSetId),
            },
            description: {
                helperText: 'Description for the webhook set',
                label: 'Webhook Set Description *',
                componentType: EditResourceComponentType.INPUT,
                validationRegex: new RegExp('[A-Z]([A-Z0-9]*[a-z][a-z0-9]*[A-Z]|[a-z0-9]*[A-Z][A-Z0-9]*[a-z])[A-Za-z0-9]*'),
            },
            webhookRegistrationIds: {
                helperText: 'The set of webhook registration ids to associate with this set',
                label: 'Associated Webhooks *',
                componentType: EditResourceComponentType.MULTI_SELECT_SEARCHABLE_LIST,
                currentValues: resource ? resource.webhookRegistrationIds : [],
                acceptedValues: webhooks.map(wh => {
                    return {label: wh.webhookRegistrationId, value: wh.webhookRegistrationId};
                })
            }
        };
    }

    async viewResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, resource: WebhookRegistrationSet): Promise<Record<string, ViewResourceConfiguration<WebhookRegistrationSet>>> {
        return {
            webhookRegistrationSetId: {
                displayName: 'Identifier',
                componentType: ViewResourceComponentType.TEXT,
            },
            description: {
                displayName: 'Description',
                componentType: ViewResourceComponentType.TEXT,
            },
            webhookRegistrationIds: {
                displayName: 'Webhook Registration IDs',
                componentType: ViewResourceComponentType.LIST,
            },
            creationTimeEpochMs: {
                displayName: 'Created',
                componentType: ViewResourceComponentType.TIME,
            },
            updateTimeEpochMs: {
                displayName: 'Updated',
                componentType: ViewResourceComponentType.TIME,
            },
        };
    }

    async listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<WebhookRegistrationSet>>> {
        return {
            webhookRegistrationSetId: {
                displayName: 'Identifier',
                componentType: ListResourceComponentType.TEXT,
                onClick: id => this.navigate(RouteName.WEBHOOK_SETS + `/${id}`),
                sortable: true
            },
            description: {
                displayName: 'Description',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            }
        };
    }

    getId(resource: WebhookRegistrationSet): string {
        return resource.webhookRegistrationSetId;
    }


}
