/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Select, {SelectOption} from '@amzn/meridian/select';
import {SelectOptionProps} from '@amzn/meridian/select/select-option';
import Text from '@amzn/meridian/text';
import Textarea from '@amzn/meridian/textarea';
import React, {Dispatch, SetStateAction} from 'react';

import {EditResourceComponentType, EditResourceConfiguration} from '../Resources/Configurations/IPutResource';
import MapEditor, {MapPair} from './MapEditor';
import SearchSelect from './SearchSelect';


/**
 * The reducer for details.
 * @param state the details state.
 * @param newValues new state values
 * @returns {T,} the new state
 */
export const detailsReducer = <T, >(state: T, newValues: any) => {
    return {
        ...state,
        ...newValues
    };
};


export const KeyValueEdit = <T, >(
    {
        details, setDetails, inEditMode, onError, setIdentifier, editConfiguration
    }: {
        details: T,
        setDetails: (val: any) => void,
        inEditMode: boolean
        onError: Dispatch<SetStateAction<boolean>>
        setIdentifier?: Dispatch<SetStateAction<string>>
        editConfiguration: Record<string, EditResourceConfiguration>
    }) => {

    const [searchSelectMulti, setSearchMultiSelect] = React.useState<Record<string, any[]>>({});

    React.useEffect(() => {
        if (editConfiguration) {
            setSearchMultiSelect(Object.keys(details)
                .reduce((acc, item) => (
                    {
                        ...acc,
                        [item]: item in editConfiguration && 'existingSelected' in editConfiguration[item] ? editConfiguration[item]['currentValues'] : []
                    }), {}));
        }
    }, [editConfiguration]);

    /**
     * Checks to see if the value for this key is valid.
     * @param value to evaluate.
     * @param keyConfig the key config.
     * @returns {boolean} whether it's in error.
     */
    const inError = (value: string, keyConfig: EditResourceConfiguration): boolean => {
        let isInError = false;
        if (keyConfig.editable) {
            let isNull = value === '';
            let isNotMatch = keyConfig.validationRegex ? !keyConfig.validationRegex.test(value) : false;
            if (keyConfig.isIdentifier) {
                if (inEditMode) {
                    isInError = false;
                } else if (keyConfig.disallowedValues) {
                    let idInUse = keyConfig.disallowedValues.includes(value);
                    isInError = isNull || isNotMatch || idInUse;
                }
            } else {
                isInError = isNull || isNotMatch;
            }
        } else {
            isInError = false;
        }
        onError(isInError);
        return isInError;
    };

    return (
        <Column width='100%'>
            {
                Object.entries(details).map(([key, value]: [string, string]) => {
                    if (!(key in editConfiguration)) {
                        return undefined;
                    }

                    let keyConfig: EditResourceConfiguration = editConfiguration[key];

                    if (keyConfig.isIdentifier) {
                        return <Input
                            data-testid={`generic-details-${key}`}
                            key={keyConfig.label}
                            id={`generic-details-${key}`}
                            value={value}
                            onChange={(val) => {
                                setDetails({[key]: val});
                                setIdentifier(val);
                            }}
                            type='text'
                            error={inError(value, keyConfig)}
                            errorMessage={inError(value, keyConfig) ? 'Resource ID Invalid or In Use' : undefined}
                            label={keyConfig.label}
                            helperText={keyConfig.helperText}
                            disabled={inEditMode || !keyConfig.editable}
                            width='100%'
                        />;
                    }

                    return (
                        <>
                            {!!editConfiguration[key].acceptedValues
                            && keyConfig.componentType === EditResourceComponentType.SELECT ?
                                <Select
                                    data-testid={`generic-details-${key}`}
                                    label={keyConfig.label}
                                    value={value}
                                    onChange={(newVal: string) => setDetails({[key]: newVal})}
                                    width={'100%'}
                                    helperText={keyConfig.helperText}
                                >
                                    {keyConfig.acceptedValues
                                        .map((props: SelectOptionProps) =>
                                            (<SelectOption key={props.value} value={props.value} label={props.label}/>))
                                    }
                                </Select>
                                : keyConfig.componentType === EditResourceComponentType.MULTI_SELECT_SEARCHABLE_LIST ?
                                    <SearchSelect
                                        data-testid={`generic-details-${key}`}
                                        label={keyConfig.label}
                                        placeholder='Select'
                                        options={keyConfig.acceptedValues}
                                        isLoading={false}
                                        loadingMessage='Loading Values'
                                        selectedValue={searchSelectMulti[key]}
                                        setSelectedValue={(value: any) => {
                                            setSearchMultiSelect(prevState => ({...prevState, [key]: value}));
                                            setDetails({[key]: value});
                                        }}
                                    />
                                    : keyConfig.componentType === EditResourceComponentType.SINGLE_SELECT_SEARCHABLE_LIST ?
                                        <SearchSelect
                                            data-testid={`generic-details-${key}`}
                                            label={keyConfig.label}
                                            placeholder='Select'
                                            options={keyConfig.acceptedValues}
                                            isLoading={false}
                                            loadingMessage='Loading Values'
                                            selectedValue={value}
                                            setSelectedValue={(value: any) => {
                                                setDetails({[key]: value});
                                            }}
                                        />
                                        : keyConfig.componentType === EditResourceComponentType.TEXT_BOX ?
                                            <Textarea
                                                data-testid={`generic-details-${key}`}
                                                key={`generic-details-${key}`}
                                                id={`generic-details-${key}`}
                                                value={value}
                                                onChange={(newVal: string) => setDetails({[key]: newVal})}
                                                helperText={keyConfig.helperText}
                                                label={keyConfig.label}
                                                width='100%'
                                                error={inError(value, keyConfig)}
                                                errorMessage={value === '' && 'This field is required'}
                                            />
                                            : keyConfig.componentType === EditResourceComponentType.INPUT ?
                                                <Input
                                                    data-testid={`generic-details-${key}`}
                                                    key={`generic-details-${key}`}
                                                    id={`generic-details-${key}`}
                                                    value={value}
                                                    onChange={(newVal: string) => {
                                                        setDetails({[key]: newVal});
                                                    }}
                                                    helperText={keyConfig.helperText}
                                                    label={keyConfig.label}
                                                    width='100%'
                                                    error={inError(value, keyConfig)}
                                                    errorMessage={value === '' && 'This field is required'}
                                                />
                                                : keyConfig.componentType === EditResourceComponentType.MAP ?
                                                    <>
                                                        <Text>{keyConfig.label}</Text>
                                                        <MapEditor initialJson={value}
                                                            setDetails={(newVal: MapPair[]) => {
                                                                let newMap: Record<string, string> = {};
                                                                newVal.forEach(pair => {
                                                                    newMap[pair.key] = pair.value;
                                                                });
                                                                setDetails({[key]: newMap});
                                                            }}
                                                            options={keyConfig.acceptedValues.map(option => option.value.toString())}/>
                                                    </>
                                                    : undefined
                            }

                        </>
                    );
                })
            }
        </Column>
    );
};
