/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {Problem, ProblemStatus} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {NavigateFunction} from 'react-router/dist/lib/hooks';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {deleteProblem, getProblem, listProblems} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../ITableResource';
import {IViewResource} from '../IViewResource';

/**
 * Problem resource config.
 */
export class ProblemResourceConfig implements ITableResource<Problem>, IViewResource<Problem> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    navigateCreateResource?(): void {
        throw new Error('Method not implemented.');
    }

    navigateEditResource?(resource: Problem): void {
        throw new Error('Method not implemented.');
    }

    putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: Problem): Promise<Problem> {
        throw new Error('Method not implemented.');
    }

    initializeDefaultObject(): Problem {
        throw new Error('Method not implemented.');
    }

    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Problem[]> {
        const today = new Date(); // Get today's date
        const threeDays = new Date(today.getTime() - 3 * 24 * 60 * 60 * 1000); // Subtract 7 days worth of milliseconds
        const timeInMs = threeDays.getTime(); // Get the time in milliseconds
        return await listProblems(webStageConfig, selectedRegion,
            {
                identificationTimeEpochMs: timeInMs,
                problemStatus: ProblemStatus.OPEN
            });
    }


    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<Problem> {
        return getProblem(webStageConfig, region, {problemId: resourceId});
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: Problem) {
        await deleteProblem(webStageConfig, region, {problemId: resource.problemId});
    }

    navigateViewResource(resource: Problem): void {
        this.navigate(`${RouteName.PROBLEMS}/${resource.problemId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.PROBLEMS);
    }

    async listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<Problem>>> {
        return {
            problemId: {
                componentType: ListResourceComponentType.TEXT,
                displayName: 'Identifier',
                onClick: id => this.navigate(RouteName.PROBLEMS + `/${id}`),
                maxValueLength: 15
            },
            problemFinderId: {
                componentType: ListResourceComponentType.TEXT,
                displayName: 'Problem Finder ID',
                onClick: (id) => this.navigate(RouteName.PROBLEM_FINDERS + `/${id}`),
                sortable: true
            },
            impactLevel: {
                componentType: ListResourceComponentType.TAG,
                displayName: 'Impact',
                sortable: true
            },
            problemScope: {
                componentType: ListResourceComponentType.TEXT,
                displayName: 'Scope',
                sortable: true
            },
            problemType: {
                componentType: ListResourceComponentType.TAG,
                displayName: 'Type',
                sortable: true
            },
            updateTimeEpochMs: {
                componentType: ListResourceComponentType.TIME,
                displayName: 'Updated',
                sortable: true
            },
        };
    }


    resourceName(): string {
        return 'Recent Problem';
    }

    getId(resource: Problem): string {
        return resource.problemId;
    }
}
