/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import Select, {SelectOption} from '@amzn/meridian/select';
import {SelectOptionProps} from '@amzn/meridian/select/select-option';
import Text from '@amzn/meridian/text';
import {escapeRegExp} from 'lodash';
import React from 'react';

import LoadingMessage from '../components/utility-views/LoadingMessage';

export type UseSearchSelectProps = {
    label: string
    placeholder: string
    options: SelectOptionProps[]
    disabled?: boolean
    constraintText?: string
    isLoading: boolean
    loadingMessage: string
    allowMultiSelect?: boolean
    width?: string
}

export const useSearchSelect = (props: UseSearchSelectProps): [string | string[], React.Dispatch<React.SetStateAction<string | string[]>>, React.ReactElement] => {
    const {
        allowMultiSelect,
        options
    } = props;

    // If there is only one option, auto-select it as the value
    const [value, setValue] = React.useState<string | string[]>(options.length === 1
        ? (allowMultiSelect ? [String(options[0].value)] : String(options[0].value))
        : (allowMultiSelect ? [] : undefined));

    const [searchQuery, setSearchQuery] = React.useState<string>();
    const deferredSearchQuery = React.useDeferredValue(searchQuery);

    const searchRegExp = new RegExp(escapeRegExp(searchQuery), 'i');
    const matchedItems = options.filter(
        option => !searchQuery || searchRegExp.test(option.label as string)
    );

    const selectOptions = React.useMemo(
        () => matchedItems.map(item => (
            <SelectOption key={item.value} label={item.label} value={item.value} data-testid={`${props.label}-${item.value}`} />
        )),
        [matchedItems, deferredSearchQuery]
    );

    const select = (
        <Select
            label={props.label}
            value={value}
            onChange={(newValue) => setValue(value === newValue ? undefined : newValue)}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder={props.placeholder}
            width={props.width || '100%'}
            disabled={props.disabled === true}
            constraintText={props.constraintText}
        >
            {selectOptions}
            {props.isLoading ? (
                // If there are no options at all, we display a loading message to our users
                <Column alignmentVertical='center' spacing='300' spacingInset='300'>
                    <LoadingMessage size='small' message={props.loadingMessage} />
                </Column>
            ) : null}
            {matchedItems.length === 0 && !props.isLoading ? (
                // If there are no matched options, we display a message to our
                // users saying their query returned no results
                <Column alignmentVertical='center' spacing='300' spacingInset='300'>
                    <Text alignment='center'>No results</Text>
                </Column>
            ) : null}
        </Select>
    );

    return [value, setValue, select];
};

