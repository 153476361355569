/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {StatusChangeStrategy} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import React from 'react';

import SearchSelect from '../utility-views/SearchSelect';

export type ClosureStrategyInputProps = {
    closureStrategyValue: StatusChangeStrategy
    setClosureStrategyValue: (arg0: StatusChangeStrategy) => void
}

const ClosureStrategyInput = ({closureStrategyValue, setClosureStrategyValue}: ClosureStrategyInputProps) => {
    return (
        <SearchSelect
            label='Closure strategy'
            placeholder='Select closure strategy'
            options={Object.entries(StatusChangeStrategy).map(([label, value]) => ({ label, value }))}
            isLoading={false}
            loadingMessage='Loading strategies...'
            selectedValue={closureStrategyValue}
            setSelectedValue={setClosureStrategyValue}
        />
    );
};

export default ClosureStrategyInput;
