/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {EscalationMap} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import Column from '@amzn/meridian/column';
import {ColumnProps} from '@amzn/meridian/column/column';
import React from 'react';

import SearchSelect from '../utility-views/SearchSelect';

export type EscalationMapInputProps = {
    width?: ColumnProps['width']
    escalationMap: EscalationMap
    setEscalationMap: (arg0: EscalationMap) => void
    options: string[]
}

const EscalationMapInput = ({width, escalationMap, setEscalationMap, options}: EscalationMapInputProps) => {

    const [minorValue, setMinorValue] = React.useState<string>(escalationMap?.MINOR);
    const [moderateValue, setModerateValue] = React.useState<string>(escalationMap?.MODERATE);
    const [majorValue, setMajorValue] = React.useState<string>(escalationMap?.MAJOR);
    const [criticalValue, setCriticalValue] = React.useState<string>(escalationMap?.CRITICAL);
    const [severeValue, setSevereValue] = React.useState<string>(escalationMap?.SEVERE);

    React.useEffect(() => {
        setEscalationMap({
            'CRITICAL': criticalValue,
            'MAJOR': majorValue,
            'MINOR': minorValue,
            'MODERATE': moderateValue,
            'SEVERE': severeValue,
        });
    }, [minorValue, moderateValue, majorValue, criticalValue, severeValue]);

    const optionsForSelect = options.map(opt => ({label: opt, value: opt}));

    return (
        <Column width={width || '100%'}>
            <SearchSelect label='Minor' placeholder='' options={optionsForSelect} isLoading={false} loadingMessage='' selectedValue={minorValue} setSelectedValue={setMinorValue} />
            <SearchSelect label='Moderate' placeholder='' options={optionsForSelect} isLoading={false} loadingMessage='' selectedValue={moderateValue} setSelectedValue={setModerateValue} />
            <SearchSelect label='Major' placeholder='' options={optionsForSelect} isLoading={false} loadingMessage='' selectedValue={majorValue} setSelectedValue={setMajorValue} />
            <SearchSelect label='Critical' placeholder='' options={optionsForSelect} isLoading={false} loadingMessage='' selectedValue={criticalValue} setSelectedValue={setCriticalValue} />
            <SearchSelect label='Severe' placeholder='' options={optionsForSelect} isLoading={false} loadingMessage='' selectedValue={severeValue} setSelectedValue={setSevereValue} />
        </Column>
    );
};

export default EscalationMapInput;
