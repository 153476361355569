/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {StageConfiguration} from '@amzn/id4-web-constants';
import {InvokeCommand} from '@aws-sdk/client-lambda';
import {fromUtf8, toUtf8} from '@aws-sdk/util-utf8-node';

import {createLambdaClient} from './aws-client';

/**
 * Invokes a lambda function and returns the results.
 *
 * @param stageConfiguration client stage configuration
 * @param lambdaArn ARN of the lambda to invoke
 * @param payload optional payload to provide into the lambda
 * @returns {{ statusCode: number, payload: string }} status code and payload/error message
 */
export async function invokeLambda(stageConfiguration: StageConfiguration, lambdaArn: string, payload?: any): Promise<{ statusCode: number, payload: string }> {
    const client = await createLambdaClient(stageConfiguration);
    const response = await client.send(new InvokeCommand({
        FunctionName: lambdaArn,
        Payload: fromUtf8(JSON.stringify(payload ?? {}))
    }));
    return {
        statusCode: response.StatusCode,
        payload: response.StatusCode === 200 ? toUtf8(response.Payload) : response.FunctionError
    };
}
