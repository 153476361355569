/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import SideMenu, {SideMenuLink} from '@amzn/meridian/side-menu';
import dashboardTokens from '@amzn/meridian-tokens/base/icon/dashboard';
import folderTokens from '@amzn/meridian-tokens/base/icon/folder';
import React from 'react';
import {useSelector} from 'react-redux';

import {selectSideMenuOpen} from '../../../state/app/appSlice';
import {RouteName} from '../../Routing/RoutingPage';


const AppSideMenu = () => {

    const [resourcesOpen, setResourcesOpen] = React.useState<boolean>(false);
    const sideMenuOpen = useSelector(selectSideMenuOpen);


    // TODO: selected doesn't update on navigate
    const windowContainsRoute = (route: string) => {
        return window.location.pathname === (route);
    };

    return (
        <SideMenu open={sideMenuOpen}>
            <SideMenuLink icon={dashboardTokens} href={RouteName.DASHBOARD}
                selected={windowContainsRoute(RouteName.DASHBOARD)}>
                Dashboard
            </SideMenuLink>
            <SideMenuLink icon={folderTokens} open={resourcesOpen}
                onClick={() => setResourcesOpen(!resourcesOpen)}>
                Resources
                <SideMenuLink href={RouteName.PROBLEMS}
                    selected={windowContainsRoute(RouteName.PROBLEMS)}>
                    Recent Open Problems
                </SideMenuLink>
                <SideMenuLink href={RouteName.PROBLEM_FINDERS}
                    selected={windowContainsRoute(RouteName.PROBLEM_FINDERS)}>
                    Problem Finders
                </SideMenuLink>
                <SideMenuLink href={RouteName.REPORT_CONFIGURATIONS}
                    selected={windowContainsRoute(RouteName.REPORT_CONFIGURATIONS)}>
                    Report Configurations
                </SideMenuLink>
                <SideMenuLink href={RouteName.PROBLEM_TYPES}
                    selected={windowContainsRoute(RouteName.PROBLEM_TYPES)}>
                    Problem Types
                </SideMenuLink>
                <SideMenuLink href={RouteName.OVERRIDES}
                    selected={windowContainsRoute(RouteName.OVERRIDES)}>
                    Overrides
                </SideMenuLink>
                <SideMenuLink href={RouteName.WEBHOOKS}>
                    Webhooks
                </SideMenuLink>
                <SideMenuLink href={RouteName.WEBHOOK_SETS}
                    selected={windowContainsRoute(RouteName.WEBHOOK_SETS)}>
                    Webhook Sets
                </SideMenuLink>
            </SideMenuLink>

        </SideMenu>
    );
};

export default AppSideMenu;
