/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */


export const capitalize = (str: string) => {
    if (!str) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatTimestamp = (timestamp: any, locale: string, timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
    const dtFormat = new Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
        timeStyle: 'short',
        timeZone: timeZone
    });

    // This function will add a Narrow No-Break Space (NNBSP) whitespace character between the time and AM/PM,
    // so replace that with a regular space
    return dtFormat.format(new Date(timestamp)).replace(' ', ' ');
};

