/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {WebStageConfig} from '../../../config/id4-portal-config';
import {SupportedRegions} from '../../../state/app/appSlice';
import {IDeleteResource} from './IDeleteResource';

/**
 * The view resource component type.
 */
export enum ViewResourceComponentType {
    EXPANDABLE_TEXT,
    TEXT,
    TEXT_BOX,
    TIME,
    BADGE,
    TAG,
    CTI,
    LIST,
    JSON,
}

/**
 * The view resource configuration.
 */
export class ViewResourceConfiguration<T> {
    displayName: string;
    componentType?: ViewResourceComponentType;
    styleRetrieve?: (resource: T) => any;
    onClick?: (value: string) => void;
}

/**
 * The view resource interface.
 */
export interface IViewResource<T> extends IDeleteResource<T>{

    /**
     * The name of the resource.
     */
    resourceName(): string

    /**
     * Navigate to view resource if it has a view.
     * @param resource to view.
     */
    navigateViewResource?(resource: T): void;

    /**
     * Navigate to edit resource. If it is editable in web app.
     * @param resource to edit.
     */
    navigateEditResource?(resource: T): void;

    /**
     * Navigate to table view.
     */
    navigateTableView(): void;

    /**
     * Retrieves the given resource.
     * @param webStageConfig the web stage config.
     * @param region the region to pull from.
     * @param resourceId the id of the resource.
     */
    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<T>

    /**
     * The view resource configuration. Defines how each resource is viewed. If not implemented, defaults to a KeyValue display.
     * @param webStageConfig the web stage.
     * @param selectedRegion the region.
     * @param resource the resource to view.
     */
    viewResourceConfiguration?(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, resource: T): Promise<Record<string, ViewResourceConfiguration<T>>>

}
