/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import Select, {SelectOption} from '@amzn/meridian/select';
import {SelectOptionProps} from '@amzn/meridian/select/select-option';
import Text from '@amzn/meridian/text';
import {escapeRegExp} from 'lodash';
import React from 'react';

import LoadingMessage from './LoadingMessage';

/**
 * The search select props.
 */
export type SearchSelectProps = {
    label: string
    placeholder: string
    options: SelectOptionProps[]
    disabled?: boolean
    constraintText?: string
    isLoading: boolean
    loadingMessage: string
    width?: string
    /**
     * Pass in an array here to allow multiselect.
     */
    selectedValue: string | string[]
    setSelectedValue: ((arg0: string) => void) | ((arg0: string[]) => void)
}

const SearchSelect = ({
    label, placeholder, options, disabled, constraintText, isLoading, loadingMessage, width,
    selectedValue, setSelectedValue
}: SearchSelectProps) => {

    const [searchQuery, setSearchQuery] = React.useState<string>();
    const deferredSearchQuery = React.useDeferredValue(searchQuery);

    const searchRegExp = new RegExp(escapeRegExp(searchQuery), 'i');
    const matchedItems = options.filter(
        option => !searchQuery || searchRegExp.test(option.label as string)
    );

    /**
     * The select options.
     */
    const selectOptions = React.useMemo(
        () => matchedItems.map(item => (
            <SelectOption key={item.value} label={item.label} value={item.value} data-testid={`${label}-${item.value}`}/>
        )),
        [matchedItems, deferredSearchQuery]
    );

    /**
     * Gets the set of values to select.
     * @param value that has just been selected.
     * @returns {any} the value set.
     */
    function getValueSet(value: any): any {
        if (Array.isArray(selectedValue)) {
            return value;
        } else {
            return value === selectedValue ? undefined : value;
        }
    }

    return (
        <Select
            label={label}
            value={selectedValue}
            onChange={(value: any) => setSelectedValue(getValueSet(value))}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder={placeholder}
            width={width || '100%'}
            disabled={disabled === true}
            constraintText={constraintText}
        >
            {selectOptions}
            {
                isLoading && (
                    // If there are no options at all, we display a loading message to our users
                    <Column alignmentVertical='center' spacing='300' spacingInset='300'>
                        <LoadingMessage size='small' message={loadingMessage}/>
                    </Column>
                )
            }
            {
                matchedItems.length === 0 && !isLoading && (
                    // If there are no matched options, we display a message to our
                    // users saying their query returned no results
                    <Column alignmentVertical='center' spacing='300' spacingInset='300'>
                        <Text alignment='center'>No results</Text>
                    </Column>
                )
            }
        </Select>
    );
};

export default SearchSelect;
