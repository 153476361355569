/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';

export type KeyValueDisplayProps = {
    keyId: string
    columns: { key: string, value: string }[][]
    title?: string
}

const KeyValueDisplay = (props: KeyValueDisplayProps) => {
    return (
        <>
            {props.title && <Text type='h300'>{props.title}</Text>}
            <Box type='outline' spacingInset='400'>
                <Column>
                    <Row alignmentVertical='top' spacing='600' wrap='down'>
                        {
                            props.columns.map((column, ii) => {
                                return (
                                    <Column key={props.keyId + ii} alignmentVertical='top' data-testid={`key-value-column-${ii}`}>
                                        {
                                            column.map(({key, value}) => (
                                                <Column key={props.keyId + key} spacing={'100'}>
                                                    <Text type='h100'>{key}</Text>
                                                    <Text className={'keyValueTextBox'}>{value}</Text>
                                                </Column>
                                            ))
                                        }
                                    </Column>
                                );
                            })
                        }
                    </Row>
                </Column>
            </Box>
        </>
    );
};

export default KeyValueDisplay;
