/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {StageConfiguration} from '@amzn/id4-web-constants';
import {CognitoIdentityClient} from '@aws-sdk/client-cognito-identity';
import {LambdaClient} from '@aws-sdk/client-lambda';
import {
    CognitoIdentityCredentialProvider,
    CognitoIdentityCredentials,
    fromCognitoIdentityPool
} from '@aws-sdk/credential-provider-cognito-identity';
import {Auth} from 'aws-amplify';

const SERVICE_CREDENTIAL_EXPIRY_MARGIN = 5 * 60 * 1000;

/**
 * Helper method to check if credentials have an expiration coming up or is already expired.
 *
 * @param inputDate the given input date.
 * @returns {boolean} true if the date is close to expiring. False if not.
 */
export function shouldRefreshDueToExpiration(inputDate: Date): boolean {
    return inputDate <= new Date(new Date().getTime() + SERVICE_CREDENTIAL_EXPIRY_MARGIN);
}

/**
 * Get temporary AWS credentials.
 *
 * @param stageConfiguration client stage configuration
 * @returns {Promise<CognitoIdentityCredentialProvider>} CognitoIdentityCredentialProvider
 */
async function getCredentialsProvider(stageConfiguration: StageConfiguration): Promise<CognitoIdentityCredentialProvider> {
    const {region, identityPoolId, userPoolId} = stageConfiguration.cognito;
    const cognitoId = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
    const loginData = {
        // Auth.currentSession will automatically refresh the tokens if they are expired
        [cognitoId]: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    return fromCognitoIdentityPool({
        client: new CognitoIdentityClient({region}),
        identityPoolId: identityPoolId,
        logins: {
            ...loginData,
        },
    });
}

/**
 * Retrieves temporary AWS Cognito identity credentials.
 *
 * @param stageConfiguration client stage configuration
 * @returns {Promise<CognitoIdentityCredentials>} CognitoIdentityCredentials
 */
export async function getCognitoIdentityCredentials(stageConfiguration: StageConfiguration): Promise<CognitoIdentityCredentials> {
    const credentialProvider = await getCredentialsProvider(stageConfiguration);
    return await credentialProvider();
}

let lambdaClient: LambdaClient;

/**
 * Creates a Lambda Client with the user's JWT.
 *
 * @param stageConfiguration client stage configuration
 * @returns {Promise<LambdaClient>} LambdaClient
 */
export async function createLambdaClient(stageConfiguration: StageConfiguration): Promise<LambdaClient> {
    if (lambdaClient) {
        const clientCredentials = await lambdaClient.config.credentials();
        if (!shouldRefreshDueToExpiration(clientCredentials.expiration)) {
            return lambdaClient;
        }
    }

    lambdaClient = new LambdaClient({
        region: stageConfiguration.cognito.region,
        credentials: await getCredentialsProvider(stageConfiguration)
    });
    return lambdaClient;
}
