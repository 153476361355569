/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {ScopeType} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {WebhookRegistration, WebhookType} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/scheduler';
import {NavigateFunction} from 'react-router/dist/lib/hooks';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {
    deleteWebhook,
    getWebhook,
    listWebhooks,
    listWebhookSets,
    putWebhook,
    putWebhookSet
} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {IDeleteResource} from '../IDeleteResource';
import {EditResourceComponentType, EditResourceConfiguration, IPutResource} from '../IPutResource';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../ITableResource';
import {IViewResource, ViewResourceComponentType, ViewResourceConfiguration} from '../IViewResource';


/**
 * Webhook resource config.
 */
export class WebhookResourceConfig implements IPutResource<WebhookRegistration>, IViewResource<WebhookRegistration>, ITableResource<WebhookRegistration>, IDeleteResource<WebhookRegistration> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }
    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<WebhookRegistration[]> {
        return await listWebhooks(webStageConfig, selectedRegion);
    }


    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<WebhookRegistration> {
        return getWebhook(webStageConfig, region, {webhookRegistrationId: resourceId});
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: WebhookRegistration) {
        await deleteWebhook(webStageConfig, region, {webhookRegistrationId: resource.webhookRegistrationId});
        let existingSets = await listWebhookSets(webStageConfig, region);
        // Remove any webhook ids that have now been deleted from any existing sets
        existingSets.forEach(set => {
            if (set.webhookRegistrationIds.includes(resource.webhookRegistrationId)){
                set.webhookRegistrationIds = set.webhookRegistrationIds.filter(id => id !== resource.webhookRegistrationId);
                putWebhookSet(webStageConfig, region, {
                    webhookRegistrationSetId: set.webhookRegistrationSetId,
                    webhookRegistrationSetDetails: {...set}
                });
            }
        });
    }

    async putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: WebhookRegistration): Promise<WebhookRegistration> {
        let webhooks = await this.retrieveResources(webStageConfig, region);

        if (webhooks.map(wh => wh.webhookUrl).includes(resource.webhookUrl)){
            throw new Error('Webhook URL Already Registered');
        }

        return await putWebhook(webStageConfig, region,
            {
                webhookRegistrationId: resource.webhookRegistrationId,
                webhookRegistrationDetails: {...resource}
            }
        ).then(response => response.webhookRegistration);
    }

    navigateCreateResource(): void {
        this.navigate(`${RouteName.WEBHOOKS}/create`);
    }

    navigateEditResource(resource: WebhookRegistration): void {
        this.navigate(`${RouteName.WEBHOOKS}/${resource.webhookRegistrationId}/edit`);
    }

    navigateViewResource(resource: WebhookRegistration): void {
        this.navigate(`${RouteName.WEBHOOKS}/${resource.webhookRegistrationId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.WEBHOOKS);
    }

    resourceName(): string {
        return 'Webhook';
    }

    initializeDefaultObject(): WebhookRegistration {
        return {
            webhookRegistrationId: undefined,
            ownerCti: {
                category: 'Amazon Robotics',
                type: 'GSS',
                item: 'Tools-Dev',
            },
            description: undefined,
            type: undefined,
            webhookScope: undefined,
            webhookScopeType: undefined,
            webhookUrl: undefined,
            tags: [],
        };
    }

    async viewResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, resource: WebhookRegistration): Promise<Record<string, ViewResourceConfiguration<WebhookRegistration>>> {
        return {
            webhookRegistrationId: {
                displayName: 'Identifier',
                componentType: ViewResourceComponentType.TEXT,
            },
            type: {
                displayName: 'Webhook Type',
                componentType: ViewResourceComponentType.TEXT,
            },
            description: {
                displayName: 'Description',
                componentType: ViewResourceComponentType.TEXT,
            },
            webhookScope: {
                displayName: 'Webhook Scope',
                componentType: ViewResourceComponentType.TEXT,
            },
            webhookScopeType: {
                displayName: 'Webhook Scope Type',
                componentType: ViewResourceComponentType.TEXT,
            },
            webhookUrl: {
                displayName: 'Hook',
                componentType: ViewResourceComponentType.TEXT,
            },
            creationTimeEpochMs: {
                displayName: 'Created',
                componentType: ViewResourceComponentType.TIME,
            },
            updateTimeEpochMs: {
                displayName: 'Updated',
                componentType: ViewResourceComponentType.TIME,
            },
        };
    }

    async editResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, EditResourceConfiguration>> {
        let webhookRegistrationIds = await listWebhooks(webStageConfig, selectedRegion);

        return {
            webhookRegistrationId: {
                helperText: 'The unique identifier for your webhook. Example: WebhookNameX',
                label: 'Webhook ID *',
                isIdentifier: true,
                editable: true,
                validationRegex: new RegExp('[A-Z]([A-Z0-9]*[a-z][a-z0-9]*[A-Z]|[a-z0-9]*[A-Z][A-Z0-9]*[a-z])[A-Za-z0-9]*'),
                disallowedValues: webhookRegistrationIds.map(v => v.webhookRegistrationId),
            },
            type: {
                helperText: 'The type of webhook.',
                label: 'Webhook Type *',
                componentType: EditResourceComponentType.SELECT,
                acceptedValues: [WebhookType.SLACK, WebhookType.CHIME].map(v => {
                    return {label: v, value: v};
                })
            },
            description: {
                helperText: 'The description for this webhook',
                label: 'Description *',
                componentType: EditResourceComponentType.TEXT_BOX
            },
            webhookScope: {
                helperText: 'The webhook\'s scope',
                label: 'Webhook Scope *',
                componentType: EditResourceComponentType.INPUT,
                validationRegex: new RegExp('^(?:(^NA$)|(^EU$)|(^AP$)|(^[A-Za-z0-9]+\\/([^/ ]+)\\/([^/ ]+)$)|(^\\w{4}$)|(^\\*$))$')
            },
            webhookScopeType: {
                helperText: 'The webhook\'s scope type',
                label: 'Webhook Scope Type',
                componentType: EditResourceComponentType.SELECT,
                acceptedValues: [ScopeType.KSK, ScopeType.REGION, ScopeType.BUILDING].map(v => {
                    return {label: v, value: v};
                })
            },
            webhookUrl: {
                helperText: 'The webhook url',
                label: 'Webhook URL *',
                componentType: EditResourceComponentType.INPUT,
                validationRegex: new RegExp('(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})')
            }
        };

    }

    async listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<WebhookRegistration>>> {
        return {
            webhookRegistrationId: {
                displayName: 'Identifier',
                componentType: ListResourceComponentType.TEXT,
                maxValueLength: 20,
                onClick: id => this.navigate(RouteName.WEBHOOKS + `/${id}`),
                sortable: true
            },
            description: {
                displayName: 'Label',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            type: {
                displayName: 'Type',
                componentType: ListResourceComponentType.TAG,
                sortable: true
            },
            webhookScope: {
                displayName: 'Scope',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            webhookScopeType: {
                displayName: 'Scope Type',
                componentType: ListResourceComponentType.TAG,
                sortable: true
            },
            webhookUrl: {
                displayName: 'Hook',
                componentType: ListResourceComponentType.TEXT,
                sortable: true,
                maxValueLength: 30,
            },
        };
    }

    getId(resource: WebhookRegistration): string {
        return resource.webhookRegistrationId;
    }


}
