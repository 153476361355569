/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Expander from '@amzn/meridian/expander';
import React, {useState} from 'react';

export type ControlledExpanderProps = React.PropsWithChildren<{
    title: string
    defaultOpen?: boolean
}>

const ControlledExpander = (props: ControlledExpanderProps) => {
    const [open, setOpen] = useState(props.defaultOpen || false);
    return (
        <Expander open={open} onChange={setOpen} title={props.title}>
            {props.children}
        </Expander>
    );
};

export default ControlledExpander;
