/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Badge from '@amzn/meridian/badge';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import FileInput from '@amzn/meridian/file-input';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import Link from '@amzn/meridian/link';
import Modal from '@amzn/meridian/modal';
import Pagination from '@amzn/meridian/pagination';
import Row from '@amzn/meridian/row';
import Select, {SelectOption} from '@amzn/meridian/select';
import Table, {TableActionBar, TableActionBarOverlay, TableCell, TableRow,} from '@amzn/meridian/table';
import {TableSortDirection} from '@amzn/meridian/table/table';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import selectedIcon from '@amzn/meridian-tokens/base/icon/check-small';
import refreshTokens from '@amzn/meridian-tokens/base/icon/refresh';
import searchTokens from '@amzn/meridian-tokens/base/icon/search';
import {truncate} from 'lodash';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {AppContext} from '../../../app';
import {selectSelectedRegion} from '../../../state/app/appSlice';
import {formatTimestamp} from '../../../utility/format-helper';
import LoadingMessage from '../../utility-views/LoadingMessage';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../Configurations/ITableResource';

const TableResourceComponent = <T, D>({configuration}: { configuration: ITableResource<T> }) => {

    const ITEMS_PER_PAGE = 25;

    const {webStageConfig} = React.useContext(AppContext);
    const selectedRegion = useSelector(selectSelectedRegion);

    const [alertMessage, setAlertMessage] = useState<{
        type: AlertProps['type'],
        title?: AlertProps['title'],
        message: string
    }>(undefined);

    const [selected, setSelected] = useState<T[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [itemsPerPage, setItemsPerPage] = useState<number>(ITEMS_PER_PAGE);
    const checkedCount = Object.values(selected).filter(Boolean).length;

    const [deleteConfirmationOpen, setDeleteConfirmationModalOpen] = useState<boolean>(false);

    const onCloseActionBar = () => {
        setSelected([]);
    };

    const [listResourceConfiguration, setListResourceConfiguration] = useState<Record<string, ListResourceConfiguration<T>>>({});
    const [filteredResources, setFilteredResources] = useState<T[]>([]);
    const [loadingResources, setLoadingResources] = useState<boolean>(true);
    const [resourcesMap, setResourcesMap] = useState<Record<string, T>>({});

    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
    const numberOfPages = Math.ceil(Object.values(resourcesMap).length / itemsPerPage);

    const [sortColumn, setSortColumn] = useState<string>(undefined);
    const [sortDirection, setSortDirection] = useState<TableSortDirection>('ascending');

    /**
     * When file is selected to upload, parses file and submits resources.
     * @param acceptedFiles files selected.
     */
    const onResourceUpload = async (acceptedFiles: File[]) => {
        for (const file of acceptedFiles) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const contents = reader.result as string;
                const jsonData = JSON.parse(contents) as T[];
                if (!isArrayOfT(jsonData)) {
                    setAlertMessage({
                        type: 'error',
                        message: 'Ensure the file you uploaded contains a JSON array of the proper resource type.',
                        title: 'Invalid JSON for Resource'
                    });
                } else {
                    for (const resource of jsonData) {
                        try {
                            await configuration.putResource(webStageConfig, selectedRegion, resource);
                        } catch (e) {
                            console.log(e);
                            setAlertMessage({
                                type: 'error',
                                message: 'Ensure the resource you are uploading has all the required fields and has any' +
                                    ' dependent resources properly registered.',
                                title: 'Failed to Upload Resource(s)'
                            });
                        }
                    }
                }
                await fetchResources();
            };
            await reader.readAsText(file);
        }
    };

    const onSort = ({sortColumn, sortDirection}: { sortColumn: string, sortDirection: TableSortDirection }) => {
        setSortDirection(sortDirection);
        setSortColumn(sortColumn);
    };

    /**
     * Method to delete a set of resources
     * @param resourcesToDelete the set of resources to delete.
     */
    const deleteResources = async (resourcesToDelete: T[]) => {
        for (const resource of resourcesToDelete) {
            await configuration.deleteResource(webStageConfig, selectedRegion, resource)
                .catch((err: any) => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error deleting resource(s)'
                    });
                    console.error(err);
                });
        }

        setAlertMessage({
            type: 'success',
            message: '',
            title: 'Resource Deleted'
        });

        // Delete the resource from the list
        setFilteredResources(prevResources => prevResources
            .filter(resource => !resourcesToDelete.includes(resource)));

        setSelected([]);

    };

    /**
     * Method used to fetch resources.
     */
    const fetchResources = async () => {
        try {
            // Reset the configuration state
            setLoadingResources(true);
            // Then retrieve the list of report resources
            let currentResources = await configuration.retrieveResources(webStageConfig, selectedRegion);
            let viewConfig = await configuration.listResourceConfiguration(webStageConfig, selectedRegion);
            setListResourceConfiguration(viewConfig);
            for (const resource of currentResources) {
                for (const key of Object.keys(viewConfig)) {
                    let config = viewConfig[key];
                    let newValue = config.valueRetrieve ?
                        await config.valueRetrieve(resource) : resource[key as keyof typeof resource];

                    setResourcesMap(prevState => (
                        {
                            ...prevState,
                            [configuration.getId(resource)]: {
                                ...prevState[configuration.getId(resource)],
                                [key]: newValue
                            }
                        }));
                }
            }

        } catch (err) {
            setAlertMessage({
                type: 'error',
                message: err.message,
                title: 'Error loading known resources.'
            });
            console.error(err);
        }

        setLoadingResources(false);
    };


    /**
     * Effect which gets a list of resources.
     */
    React.useEffect(() => {
        fetchResources();
    }, [selectedRegion]);

    /**
     * Effect which will use the input value to filter the problem finder IDs.
     */
    React.useEffect(() => {
        if (inputValue) {
            setFilteredResources(Object.values(resourcesMap).filter(resource => {
                return Object.values(resource).map(v => !v ? '' : v.toString().toLowerCase())
                    .join('').includes(inputValue.toLowerCase());
            }));
        } else {
            setFilteredResources(Object.values(resourcesMap));
        }
    }, [resourcesMap, itemsPerPage, inputValue]);


    /**
     * Gets the filtered and paginated set of resources.
     */
    const filteredAndPaginatedResources = React.useMemo(() => {
        return filteredResources
            .sort((a: any, b: any) => {
                if (a[sortColumn] < b[sortColumn]) {
                    return sortDirection === 'ascending' ? -1 : 1;
                }
                if (a[sortColumn] > b[sortColumn]) {
                    return sortDirection === 'ascending' ? 1 : -1;
                }
                return 0;
            })
            .slice(firstVisibleIndex, lastVisibleIndex);
    }, [currentPage, filteredResources, sortDirection, sortColumn]);

    /**
     * Checks to see if the given data is an array of element T.
     * @param data the data to validate.
     * @returns {boolean} whether it's valid.
     */
    const isArrayOfT = (data: any): data is T[] => {
        return Array.isArray(data) && data.every(item => typeof item === 'object' && item !== null
            && Object.keys(configuration.initializeDefaultObject()).every(field => Object.keys(item).includes(field)));
    };

    /**
     * Exports the selected resources to a json file.
     */
    const handleExport = async () => {
        let resources = await configuration.retrieveResources(webStageConfig, selectedRegion);
        let resourcesIdMap: Record<string, T> = resources.reduce((acc, obj) => ({...acc, [configuration.getId(obj)]: obj}), {});
        let selectedIdSet = selected.map(s => configuration.getId(s));
        let resourcesToExport: T[] = selectedIdSet.map(id => resourcesIdMap[id]);
        const jsonData = JSON.stringify(resourcesToExport);
        const blob = new Blob([jsonData], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = ''; // Empty string for default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /**
     * Determines how to display the resource.
     *
     * @param attribute to display.
     * @param resource the display.
     *
     * @returns {React.ReactElement} the element.
     */
    function displayAttribute(attribute: string, resource: T): React.ReactElement {
        if (!(attribute in listResourceConfiguration)) {
            return <></>;
        }

        let config: ListResourceConfiguration<T> = listResourceConfiguration[attribute];
        let value: any = resourcesMap[configuration.getId(resource)][attribute as keyof typeof resource];
        let valueElement: React.ReactElement = (<></>);
        switch (config.componentType) {
            case ListResourceComponentType.TEXT:
                if (!value) {
                    return <Text>N/A</Text>;
                }
                valueElement = (
                    <Text>
                        {
                            config.maxValueLength ?
                                truncate(value.toString(), {length: config.maxValueLength})
                                : value.toString()}
                    </Text>

                );
                break;
            case ListResourceComponentType.TAG:
                if (!value) {
                    return <Tag type={'neutral'}>N/A</Tag>;
                }
                valueElement = (<Tag type={config.styleRetrieve ? config.styleRetrieve(resource) : 'theme'}>
                    {config.maxValueLength ?
                        truncate(value.toString(), {length: config.maxValueLength}).toUpperCase()
                        : value.toString().toUpperCase()}
                </Tag>
                );
                break;
            case ListResourceComponentType.BADGE:
                if (!value) {
                    return <Tag type={'neutral'}>N/A</Tag>;
                }
                valueElement = <Badge value={value}/>;
                break;
            case ListResourceComponentType.CTI:
                if (!value) {
                    return <Text>N/A</Text>;
                }
                valueElement = (
                    <Row>
                        <Text>
                            {value.category}/{value.type}/{value.item}
                        </Text>
                    </Row>
                );
                break;
            case ListResourceComponentType.TIME:
                if (!value) {
                    return <Text>N/A</Text>;
                }
                valueElement = (<Text>
                    {formatTimestamp(value, 'en-US')}
                </Text>
                );
                break;
        }

        return (
            <>
                {config.onClick ?
                    <Link onClick={() => config.onClick(value)}>{valueElement}</Link>
                    : valueElement
                }
            </>
        );
    }

    return (
        <React.Fragment>
            <Heading level={3}>{configuration.resourceName() + 's'}</Heading>
            {
                alertMessage && (
                    <Alert
                        type={alertMessage.type}
                        title={alertMessage.title}
                        onClose={() => {
                            setAlertMessage(undefined);
                        }}
                    >
                        {alertMessage.message}
                    </Alert>
                )
            }
            <Box type={'outline'} maxHeight={'70vh'} overflowY='auto' overflowX='auto'>
                <Table
                    onSort={onSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    showDividers={true}
                    spacing={'small'}
                    fixHeaderRows={true}
                    headerRows={1}
                >
                    <TableActionBar widths={[300, 'fill']}>
                        <Input
                            aria-label='InputLabel'
                            type='text'
                            width={300}
                            size='medium'
                            placeholder='Enter search query...'
                            value={inputValue}
                            suffixIconTokens={searchTokens}
                            onChange={setInputValue}
                        />
                        <Text>
                            <Badge value={filteredResources ? filteredResources.length : 0} type='theme'/>
                            results
                        </Text>
                        {checkedCount === 1 ? (
                            <TableActionBarOverlay
                                onClose={onCloseActionBar}
                                label={
                                    <span>
                                        <strong>{checkedCount}</strong>{' '}
                                        {`item${checkedCount === 1 ? '' : 's'} `}
                                        selected
                                    </span>
                                }
                                closeLabel='Cancel'
                            >
                                <Button type='link'
                                    data-testid={'UpdateButton'}
                                    disabled={!configuration.navigateEditResource}
                                    onClick={() => configuration.navigateEditResource(selected[0])}>Update</Button>
                                <Button type='link'
                                    data-testid={'ViewButton'}
                                    disabled={!configuration.navigateViewResource}
                                    onClick={() => configuration.navigateViewResource(selected[0])}>View</Button>
                                <Button type='link'
                                    data-testid={'DeleteButton'}
                                    disabled={!configuration.deleteResource}
                                    onClick={() => setDeleteConfirmationModalOpen(true)}>Delete</Button>
                                <Button type='link'
                                    data-testid={'ExportResource'}
                                    onClick={() => handleExport()}>Export Resource</Button>
                            </TableActionBarOverlay>
                        ) : null}
                        {checkedCount > 0 ? (
                            <TableActionBarOverlay
                                onClose={onCloseActionBar}
                                label={
                                    <span>
                                        <strong>{checkedCount}</strong>{' '}
                                        {`item${checkedCount === 1 ? '' : 's'} `}
                                        selected
                                    </span>
                                }
                                closeLabel='Cancel'
                            >
                                <Button type='link' onClick={() => setDeleteConfirmationModalOpen(true)}>Delete</Button>
                                <Button type='link'
                                    data-testid={'ExportResource'}
                                    onClick={() => handleExport()}>Export Resources</Button>
                            </TableActionBarOverlay>
                        ) : null}
                        <Button type='primary' size={'medium'}
                            disabled={!configuration.navigateCreateResource}
                            onClick={() => configuration.navigateCreateResource()}>Create</Button>
                        <FileInput data-testid={'UploadResource'} type='single'
                            uploadButtonDisabled={!configuration.navigateCreateResource}
                            uploadButtonLabel={'Upload Resources'} onFileAttached={onResourceUpload}>
                        </FileInput>
                        <Button type='secondary' size={'medium'}
                            data-testid={'RefreshButton'}
                            onClick={() => fetchResources()}>
                            <Icon tokens={refreshTokens}/>
                        </Button>
                    </TableActionBar>

                    {
                        loadingResources && (
                            <TableRow>
                                <TableCell columnSpan={Object.keys(listResourceConfiguration).length + 1}>
                                    <LoadingMessage size='small' message={'Loading Table Items'}
                                        alignmentHorizontal='start'/>
                                </TableCell>
                            </TableRow>
                        )
                    }
                    {
                        alertMessage && (
                            <TableRow>
                                <TableCell
                                    columnSpan={Object.keys(listResourceConfiguration).length + 1}>{'Unable to Load Table Items'}</TableCell>
                            </TableRow>
                        )
                    }
                    {
                        !loadingResources && !alertMessage && Object.values(resourcesMap).length === 0 && (
                            <TableRow>
                                <TableCell columnSpan={Object.keys(listResourceConfiguration).length + 1}>No results</TableCell>
                            </TableRow>
                        )
                    }

                    {!!filteredResources && !!filteredResources[0] && !loadingResources ?
                        <TableRow highlightOnHover={false}>
                            <TableCell key={'selectedHeader'}>
                                <Icon tokens={selectedIcon}/>
                            </TableCell>
                            {
                                Object.entries(listResourceConfiguration).map(([key, config], ii) => (
                                    <TableCell data-testid={'DataColumn-' + ii} key={key}
                                        sortColumn={config.sortable ? key : undefined} id={'header' + config}>
                                        {
                                            config.displayName ?? key
                                        }
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                        : null
                    }
                    {!loadingResources &&
                        filteredAndPaginatedResources.map((resource, ii) => (
                            <TableRow data-testid={'DataRow-' + ii.toString()} key={JSON.stringify(resource)}
                                highlightOnHover={true}>
                                <TableCell data-testid={'SelectCell-' + ii.toString()} key={'select' + JSON.stringify(resource)}>
                                    <Row alignmentVertical='center'>
                                        <Checkbox checked={selected.includes(resource)}
                                            onChange={(isChecked) => isChecked ? setSelected(selected.concat([resource])) :
                                                setSelected(selected.filter(item => item !== resource))}></Checkbox>
                                    </Row>
                                </TableCell>
                                {
                                    Object.keys(listResourceConfiguration).map(attribute => {
                                        return (
                                            <TableCell key={attribute + JSON.stringify(resource)}>
                                                {displayAttribute(attribute, resource)}
                                            </TableCell>
                                        );
                                    }
                                    )
                                }
                            </TableRow>
                        ))
                    }
                </Table>
                <Row spacingInset={'400'} alignmentHorizontal={'justify'}>
                    <Select
                        value={itemsPerPage}
                        onChange={setItemsPerPage}
                        placeholder=''
                        width={100}
                    >
                        <SelectOption value={100} label='100'/>
                        <SelectOption value={25} label='25'/>
                        <SelectOption value={10} label='10'/>
                    </Select>
                    <Pagination
                        numberOfPages={numberOfPages}
                        onChange={setCurrentPage}
                        previousPageLabel={(page) => 'Prev'}
                        nextPageLabel={(page) => 'Next'}
                        currentPage={currentPage}
                    />
                </Row>
            </Box>

            {configuration.deleteResource ?
                <Modal
                    title='Resource Deletion Confirmation'
                    open={deleteConfirmationOpen}
                    onClose={() => setDeleteConfirmationModalOpen(false)}
                    scrollContainer='viewport'
                    closeLabel='Close'
                    aria-describedby='modal-description'
                >
                    <Column>
                        {selected.length > 1 ?
                            <Text type={'h100'} id='modal-description'>Are you sure you want to delete the
                                selected {selected.length} resources?</Text>
                            : <Text type={'h100'} id='modal-description'>Are you sure you want to delete the
                                selected resource?</Text>}
                        <Row>
                            <Button type={'secondary'} onClick={() => setDeleteConfirmationModalOpen(false)}>
                                No
                            </Button>
                            <Button data-testid={'ConfirmDelete'}
                                onClick={() => deleteResources(selected) && setDeleteConfirmationModalOpen(false)}
                                type={'primary'}>
                                Yes
                            </Button>
                        </Row>
                    </Column>
                </Modal>
                : <></>
            }
        </React.Fragment>
    );
};

export default TableResourceComponent;
