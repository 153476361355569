/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import DatePicker from '@amzn/meridian/date-picker/date-picker';
import InputGroup from '@amzn/meridian/input-group';
import {InputGroupWidth} from '@amzn/meridian/input-group/input-group';
import Text from '@amzn/meridian/text';
import TimePicker from '@amzn/meridian/time-picker/time-picker';
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';
import React from 'react';

export type DateTimePickerProps = {
    inputValueInEpochMs: number
    setInputValueInEpochMs: (arg0: number) => void
    label: string
    width?: InputGroupWidth
    disablePastDates?: boolean
}

const DateTimePicker = ({label, width, disablePastDates, inputValueInEpochMs, setInputValueInEpochMs}: DateTimePickerProps) => {

    const convertedInputValue = convertValueToDateAndTime(inputValueInEpochMs);
    const [value, setValue] = React.useState(convertedInputValue);

    React.useEffect(() => {
        if (value.length === 2) {
            const date = value[0];
            const time = value[1];
            // Handle all dates as UTC
            const epochMs = new Date(`${date} ${time} UTC`).getTime();
            // Only invoke the callback if the calculated epoch time is different then the passed in value,
            // otherwise we're duplicating callbacks unnecessarily
            if (epochMs !== inputValueInEpochMs) {
                setInputValueInEpochMs(epochMs);
            }
        }
    }, [value]);

    /**
     * Converts the input value into a date string used by the DatePicker component.
     * @param val value to convert
     * @returns {string} date string
     */
    function convertValueToDateAndTime(val: number): string[] {
        if (val) {
            const epoch = new Date(0);
            epoch.setMilliseconds(val);
            return [
                `${epoch.getUTCFullYear()}-${epoch.getUTCMonth() + 1}-${epoch.getUTCDate()}`,
                `${epoch.getUTCHours()}:${epoch.getUTCMinutes()}:${epoch.getUTCSeconds()}`
            ];
        } else {
            return [];
        }
    }

    const isDisabledDate = disablePastDates ? (date: any) => isPast(parseISO(date)) && !isToday(parseISO(date)) : () => false;
    return (
        <Column width={width ?? '100%'}>
            <Text type='h100'>{label}</Text>
            <InputGroup value={value} onChange={setValue}>
                <DatePicker
                    label='Date'
                    // onChange is required here but is actually handled by the InputGroup wrapper
                    onChange={() => {}}
                    disabledDates={isDisabledDate}
                />
                <TimePicker label='Time' />
            </InputGroup>
        </Column>
    );
};

export default DateTimePicker;
