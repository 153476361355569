/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {SupportedRegions} from '../state/app/appSlice';

export const DEV_STAGE_NAME = 'Dev';

export type ApiEndpoint = {
    name: string
    endpoint: string
    regionValue: SupportedRegions
    region: string
}

export type WebStageConfig = {
    name: typeof DEV_STAGE_NAME | 'Alpha' | 'Beta' | 'Prod' | 'Gamma'
    mothershipApiEndpoints: ApiEndpoint[]
}

const ALPHA_HOST_NAME = 'alpha.id4-portal.robotics.amazon.dev';
const BETA_HOST_NAME = 'beta.id4-portal.robotics.amazon.dev';
const GAMMA_HOST_NAME = 'gamma.id4-portal.robotics.amazon.dev';
const PROD_HOST_NAME = 'prod.id4-portal.robotics.amazon.dev';

export const URL_TO_STAGE_CONFIG_MAPPING: { [host: string]: WebStageConfig } = {
    [ALPHA_HOST_NAME]: {
        name: 'Alpha',
        mothershipApiEndpoints: [
            {
                name: 'ID4MothershipAPIGateway-Alpha',
                endpoint: 'https://na.alpha.id4.robotics.amazon.dev',
                region: 'us-east-1',
                regionValue: 'Integ',
            }
        ]
    },
    [BETA_HOST_NAME]: {
        name: 'Beta',
        mothershipApiEndpoints: [
            {
                name: 'ID4MothershipAPIGateway-Beta',
                endpoint: 'https://na.beta.id4.robotics.amazon.dev',
                region: 'us-east-1',
                regionValue: 'Integ'
            }
        ]
    },
    [GAMMA_HOST_NAME]: {
        name: 'Gamma',
        mothershipApiEndpoints: [
            {
                name: 'ID4MothershipAPIGateway-Gamma-NA',
                endpoint: 'https://na.gamma.id4.robotics.amazon.dev',
                region: 'us-east-1',
                regionValue: 'NA'
            }
        ]
    },
    [PROD_HOST_NAME]: {
        name: 'Prod',
        mothershipApiEndpoints: [
            {
                name: 'ID4MothershipAPIGateway-Prod-NA',
                endpoint: 'https://na.prod.id4.robotics.amazon.dev',
                region: 'us-east-1',
                regionValue: 'NA'
            },
            {
                name: 'ID4MothershipAPIGateway-Prod-EU',
                endpoint: 'https://eu.prod.id4.robotics.amazon.dev',
                region: 'eu-west-1',
                regionValue: 'EU'
            },
            {
                name: 'ID4MothershipAPIGateway-Prod-AP',
                endpoint: 'https://nrt.prod.id4.robotics.amazon.dev',
                region: 'ap-northeast-1',
                regionValue: 'AP'
            },
        ]
    }
};

export const getWebConfigForStage = (): WebStageConfig => {
    const host = window.location.host;
    return host in URL_TO_STAGE_CONFIG_MAPPING ? URL_TO_STAGE_CONFIG_MAPPING[host] : URL_TO_STAGE_CONFIG_MAPPING[ALPHA_HOST_NAME];
};
