/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

const DISALLOWED_CHARACTERS = [
    '!', '*', `'`, '(', ')', ';', ':', '@', '&', '=', '+', '$', ',', '/', '?', '%',
    '#', '[', ']', '{', '}', '|', '\\', '^', '~', '`', ' '
];

/**
 * Validates an ID does not contain disallowed characters (mainly URL characters).
 *
 * @param id ID to validate
 * @returns {boolean} true if valid
 */
export const validateId = (id: string): boolean => {
    return DISALLOWED_CHARACTERS.every(char => !id.includes(char));
};
