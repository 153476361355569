/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {StatusChangeStrategy} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import React from 'react';

import SearchSelect from '../utility-views/SearchSelect';

export type ExpirationStrategyInputProps = {
    expirationStrategyValue: StatusChangeStrategy
    setExpirationStrategyValue: (arg0: StatusChangeStrategy) => void
}

const ExpirationStrategyInput = ({expirationStrategyValue, setExpirationStrategyValue}: ExpirationStrategyInputProps) => {
    return (
        <SearchSelect
            label='Expiration strategy'
            placeholder='Select expiration strategy'
            options={Object.entries(StatusChangeStrategy).map(([label, value]) => ({ label, value }))}
            isLoading={false}
            loadingMessage='Loading strategies...'
            selectedValue={expirationStrategyValue}
            setSelectedValue={setExpirationStrategyValue}
        />
    );
};

export default ExpirationStrategyInput;
