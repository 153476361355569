/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {Clusivity} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/common';
import {ScheduleScope} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/scheduler';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import minusCircleTokens from '@amzn/meridian-tokens/base/icon/minus-circle';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import React from 'react';

import SearchSelect from '../utility-views/SearchSelect';

export type ScheduleScopeInputProps = {
    scheduleScopeValue: ScheduleScope[]
    setScheduleScopeValue: React.Dispatch<React.SetStateAction<ScheduleScope[]>>
}

const ScheduleScopeListInput = ({scheduleScopeValue, setScheduleScopeValue}: ScheduleScopeInputProps) => {

    /**
     * Sets the schedule scope for an index in the list.
     * @param scheduleScope scope value
     * @param idx index in the list
     */
    function setScheduleScopeForInput(scheduleScope: ScheduleScope, idx: number) {
        setScheduleScopeValue(prevState => {
            return prevState.map((val, ii) => {
                if (ii === idx) {
                    return scheduleScope;
                } else {
                    return val;
                }
            });
        });
    }

    /**
     * Removes a scheduled scope from the list.
     * @param idx index to remove
     */
    function removeScheduleScope(idx: number) {
        setScheduleScopeValue(prevState => {
            return prevState.filter((val, ii) => ii !== idx);
        });
    }

    /**
     * Adds a new scope entry.
     */
    function addNewScopeInput() {
        setScheduleScopeValue(prevState => {
            return [...prevState, {
                clusivity: undefined,
                scope: undefined
            }];
        });
    }

    if (!scheduleScopeValue) {
        return null;
    }

    return (
        <Column width='100%' alignmentHorizontal='start'>
            {
                scheduleScopeValue.map((val, ii) => (
                    <ScheduleScopeInput
                        key={`schedule-scope-input-${ii}`}
                        scheduleScope={val}
                        setScheduleScope={setScheduleScopeForInput}
                        removeScheduleScope={removeScheduleScope}
                        idx={ii}
                        allowRemove={scheduleScopeValue.length > 0}
                    />
                ))
            }
            <Button type='secondary' onClick={addNewScopeInput}>
                <Icon tokens={plusCircleTokens}>Add</Icon>Add
            </Button>
        </Column>
    );
};

const ScheduleScopeInput = ({
    scheduleScope,
    setScheduleScope,
    removeScheduleScope,
    allowRemove,
    idx
}: {
    scheduleScope: ScheduleScope
    setScheduleScope: (arg0: ScheduleScope, idx: number) => void
    removeScheduleScope: (idx: number) => void
    allowRemove: boolean
    idx: number
}) => {
    return (
        <Row width='100%' alignmentVertical='bottom' widths={['40%', '40%', 'fit']}>
            <Input
                label='Scope'
                value={scheduleScope?.scope ?? ''}
                onChange={(value: string) => {
                    // Setting the scope value is handled here with the callback
                    setScheduleScope({
                        scope: value,
                        clusivity: scheduleScope?.clusivity
                    }, idx);
                }}
            />
            <SearchSelect
                label='Clusivity'
                placeholder='Select clusivity'
                options={Object.entries(Clusivity).map(([label, value]) => ({ label, value }))}
                isLoading={false}
                loadingMessage='Loading notification grouping options...'
                selectedValue={scheduleScope?.clusivity}
                setSelectedValue={(value: string) => {
                    // Setting the clusivity value is handled here with the callback
                    setScheduleScope({
                        scope: scheduleScope?.scope,
                        clusivity: value as Clusivity
                    }, idx);
                }}
            />
            <Button type='secondary' onClick={() => removeScheduleScope(idx)} disabled={!allowRemove}>
                <Icon tokens={minusCircleTokens}>Remove</Icon>Remove
            </Button>
        </Row>
    );
};


export default ScheduleScopeListInput;
