/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {Problem, ProblemStatus} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Legend, {LegendProvider} from '@amzn/meridian/legend';
import {LegendProviderData} from '@amzn/meridian/legend/legend-provider';
import Loader from '@amzn/meridian/loader';
import Pie from '@amzn/meridian/pie';
import {PieData} from '@amzn/meridian/pie/pie';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Tile from '@amzn/meridian/tile';
import {useBundle} from '@amzn/react-arb-tools';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {AppContext} from '../../app';
import {selectSelectedRegion} from '../../state/app/appSlice';
import {capitalize} from '../../utility/format-helper';
import {listProblems} from '../../utility/id4-mothership-client';


type PieLegendData = PieData & LegendProviderData;

/**
 * Dashboard component.
 * @class for dashboard.
 */
export const Dashboard = () => {
    const [commonBundle] = useBundle('common.common');

    const [alertMessage, setAlertMessage] = useState<{
            type: AlertProps['type'],
            title?: AlertProps['title'],
            message: string
        }>(undefined);


    const [loadingResources, setLoadingResources] = useState<boolean>(true);
    const {webStageConfig} = React.useContext(AppContext);
    const selectedRegion = useSelector(selectSelectedRegion);
    const [openProblems, setOpenProblems] = useState<Problem[]>([]);

    /**
     * Effect which gets a list of report configurations.
     */
    React.useEffect(() => {
        if (commonBundle !== undefined) {
            const fetchResources = async () => {
                // Reset the configuration state
                setLoadingResources(true);
                let openProblems: Problem[] = await listProblems(webStageConfig, selectedRegion,
                    {
                        problemStatus: ProblemStatus.OPEN
                    }
                );
                setOpenProblems(openProblems);
                console.log('Open Problems: {}', openProblems.length);
            };
            fetchResources()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading known resources.'
                    });
                    console.error(err);
                })
                .finally(() => setLoadingResources(false));
        }
    }, [commonBundle, selectedRegion]);


    /**
     * The currently opened problems.
     */
    let currentOpenProblems: PieLegendData[] = React.useMemo(() => {
        let openProblemMap: { [key: string]: number } = {};

        openProblems.forEach(problem => {
            if (problem.problemType in openProblemMap) {
                openProblemMap[problem.problemType] += 1;
            } else {
                openProblemMap[problem.problemType] = 1;
            }
        });
        return Object.entries(openProblemMap)
            .map((entry) => {
                return {value: entry[1], key: entry[0], label: capitalize(entry[0])};
            });
    }, [openProblems, selectedRegion]);

    return (

        <Column>
            {
                alertMessage && (
                    <Alert
                        type={alertMessage.type}
                        title={alertMessage.title}
                        onClose={() => {
                            setAlertMessage(undefined);
                        }}
                    >
                        {alertMessage.message}
                    </Alert>
                )
            }

            <Heading type={'d100'} level={2}>
                    Dashboard
            </Heading>


            <Column
                spacingInset='400 none none 400'
                spacing='200'
                height='100%'
                heights={['fit', 'fill']}
            >

                <LegendProvider data={currentOpenProblems}>
                    <Row spacing='500' alignmentHorizontal='start' alignmentVertical='top'>
                        <Tile>
                            {loadingResources ?
                                <Loader/>
                                :
                                <Pie width={300} data={currentOpenProblems} donutRatio={0.8}>
                                    <Text type='d100'>
                                        {currentOpenProblems.reduce((total, slice) => total + slice.value, 0)}
                                    </Text>
                                    <Text type='h100' color='secondary'>
                                            open problems
                                    </Text>
                                </Pie>

                            }
                            <div style={{paddingTop: '20px'}}>
                                <Column spacingInset={'300'}>
                                    <Legend
                                        direction='vertical'
                                        values={item => item.value.toLocaleString()}
                                    />
                                </Column>
                            </div>
                        </Tile>
                    </Row>
                </LegendProvider>

            </Column>

        </Column>
    );
}
;

export default Dashboard;
