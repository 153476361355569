/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import '@amzn/meridian-tokens/base/font/amazon-ember.css';

import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './app';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
