/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import React from 'react';

const Page404 = () => {
    return (
        <Column>
            404!
        </Column>
    );
};

export default Page404;


