/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Badge from '@amzn/meridian/badge';
import Box from '@amzn/meridian/box';
import Breadcrumb, {BreadcrumbGroup} from '@amzn/meridian/breadcrumb';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Expander from '@amzn/meridian/expander';
import Link from '@amzn/meridian/link';
import Modal from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {AppContext} from '../../../app';
import {selectSelectedRegion} from '../../../state/app/appSlice';
import {capitalize, formatTimestamp} from '../../../utility/format-helper';
import KeyValueDisplay from '../../utility-views/KeyValueDisplay';
import LoadingMessage from '../../utility-views/LoadingMessage';
import PageHeader from '../../utility-views/PageHeader';
import {IViewResource, ViewResourceComponentType, ViewResourceConfiguration} from '../Configurations/IViewResource';


export const ViewResourceComponent = <T, D>({configuration}: { configuration: IViewResource<T> }) => {

    const {webStageConfig} = React.useContext(AppContext);

    const selectedRegion = useSelector(selectSelectedRegion);

    const {id} = useParams();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [resource, setResource] = React.useState<T>(undefined);
    const [viewConfiguration, setViewConfiguration] = React.useState<Record<string, ViewResourceConfiguration<T>>>(undefined);

    const [expanded, setExpanded] = React.useState<Record<string, boolean>>({});
    const [deleteConfirmationOpen, setDeleteConfirmationModalOpen] = useState<boolean>(false);

    const [alertMessage, setAlertMessage] = useState<{
        type: AlertProps['type'],
        title?: AlertProps['title'],
        message: string
    }>(undefined);

    /**
     * Effect which will retrieve the problem finder registration from the URL param ID.
     */
    React.useEffect(() => {
        if (id) {
            const fetchResource = async () => {
                // Reset configuration first
                setLoading(true);
                setResource(undefined);
                // Then retrieve the configuration details
                const resource = await configuration.retrieveResource(webStageConfig, selectedRegion, id);
                setResource(resource);
                let viewConfig = configuration.viewResourceConfiguration ? await configuration
                    .viewResourceConfiguration(webStageConfig, selectedRegion, resource) : undefined;
                setViewConfiguration(viewConfig);
            };

            fetchResource()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading resource.'
                    });
                    console.error(err);
                })
                .finally(() => setLoading(false));
        }
    }, [id, selectedRegion]);


    /**
     * Get the fields from the object.
     * @param obj to get fields from.
     * @returns {string[]} the string list.
     */
    const getFields = (obj: any) => {
        if (!obj) {
            return [];
        }
        return Object.keys(obj).sort((a, b) => {
            // Check if key includes 'id'
            const aHasId = a.includes('Id');
            const bHasId = b.includes('Id');
            // Check if key ends with 'time'
            const aHasTime = a.includes('Time');
            const bHasTime = b.includes('Time');

            // Prioritize keys with 'id'
            if (aHasId && !bHasId) {
                return -1;
            }
            if (!aHasId && bHasId) {
                return 1;
            }

            if (aHasTime && !bHasTime) {
                return 1;
            }

            if (!aHasTime && bHasTime) {
                return -1;
            }

            // If neither condition differs, sort alphabetically
            return a.localeCompare(b);
        });
    };


    const deleteResource = async () => {
        await configuration.deleteResource(webStageConfig, selectedRegion, resource)
            .catch((err: any) => {
                setAlertMessage({
                    type: 'error',
                    message: err.message,
                    title: 'Error deleting resource(s)'
                });
                console.error(err);
            });
        setDeleteConfirmationModalOpen(false);
        configuration.navigateTableView();
    };

    /**
     * Determine the set of columns for these fields and objects.
     * @param fields the fields.
     * @param obj the object to get columns for.
     *
     * @returns {{ key: string, value: string }[][]} the columns
     */
    function getColumns(fields: string[], obj: any): { key: string, value: string }[][] {
        return [fields.map(field => {
            const fieldValue = obj[field as keyof typeof obj];
            let displayValue = fieldValue.toString();


            if (typeof fieldValue === 'number' && field.toUpperCase().includes('TIME')) {
                displayValue = formatTimestamp(fieldValue, 'en-US');
            }

            if (typeof fieldValue === 'object') {
                displayValue = JSON.stringify(fieldValue);
            }

            return {
                key: capitalize(field),
                value: displayValue
            };
        })];
    }


    /**
     * Determines how to display the attribute.
     * @param attribute to display.
     * @param resource the resource containing the attribute.
     *
     * @returns {React.ReactElement} the element.
     */
    function displayAttribute(attribute: string, resource: T): React.ReactElement {
        if (!(attribute in viewConfiguration)) {
            return <></>;
        }

        let attributeConfig: ViewResourceConfiguration<T> = viewConfiguration[attribute as keyof typeof viewConfiguration];
        let valueElement: React.ReactElement = (<></>);
        let value: any = resource[attribute as keyof typeof resource];

        if (!value) {
            valueElement = <>
                <Text type='h300'>{attributeConfig.displayName}</Text>
                <Text>Not Found</Text>
            </>;
        } else {
            switch (attributeConfig.componentType) {
                case ViewResourceComponentType.LIST:
                    valueElement = (
                        <>
                            <Text type='h300'>{attributeConfig.displayName}</Text>
                            {value.map((item: string, ii: number) => (
                                <Text key={item + ii}>{item}{ii !== value.length - 1 ? ',' : ''}</Text>))
                            }
                        </>
                    );
                    break;
                case ViewResourceComponentType.TEXT_BOX:
                    valueElement = (<>
                        <Text type='h300'>{attributeConfig.displayName}</Text>
                        <Box type={'fill'} spacingInset={'400'}>{value.toString()}</Box>
                    </>);
                    break;
                case ViewResourceComponentType.TEXT:
                    valueElement = (<>
                        <Text type='h300'>{attributeConfig.displayName}</Text>
                        <Text>{value.toString()}</Text>
                    </>);
                    break;
                case ViewResourceComponentType.EXPANDABLE_TEXT:
                    valueElement = (
                        <Expander
                            open={expanded[attribute]} title={attributeConfig.displayName}
                            onChange={isOpen => {
                                setExpanded({...expanded, [attribute]: isOpen});
                            }}>
                            <Text>{value.toString()}</Text>
                        </Expander>);
                    break;
                case ViewResourceComponentType.TAG:
                    valueElement = (
                        <>
                            <Text type='h300'>{attributeConfig.displayName}</Text>
                            <Tag type={attributeConfig.styleRetrieve ? attributeConfig.styleRetrieve(resource) : 'theme'}>
                                {value.toString().toUpperCase()}
                            </Tag>
                        </>
                    );
                    break;
                case ViewResourceComponentType.BADGE:
                    valueElement = <>
                        <Text type='h300'>{attributeConfig.displayName}</Text>
                        <Badge
                            type={attributeConfig.styleRetrieve ? attributeConfig.styleRetrieve(resource) : 'theme'}
                            value={value}
                        />
                    </>;
                    break;
                case ViewResourceComponentType.CTI:
                    valueElement = (
                        <>
                            <Text type='h300'>{attributeConfig.displayName}</Text>
                            <Text>
                                {value.category}/{value.type}/{value.item}{value.group ? '/' + value.group : ''}
                            </Text>
                        </>
                    );
                    break;
                case ViewResourceComponentType.JSON: {
                    const columns = getColumns(getFields(value), value);
                    valueElement = (
                        <KeyValueDisplay keyId={value.toString()} title={attributeConfig.displayName} columns={columns}/>);
                    break;
                }
                case ViewResourceComponentType.TIME:
                    valueElement = (
                        <>
                            <Text type='h300'>{attributeConfig.displayName}</Text>
                            <Text>
                                {formatTimestamp(value, 'en-US')}
                            </Text>
                        </>
                    );
                    break;
            }
        }
        return (
            <>
                {attributeConfig.onClick ?
                    <Link onClick={() => attributeConfig.onClick(value)}>{valueElement}</Link>
                    : valueElement
                }
            </>
        );
    }

    return (
        <Column width='100%' alignmentVertical='top'>
            <BreadcrumbGroup>
                <Breadcrumb onClick={() => configuration.navigateTableView()}>{configuration.resourceName()}</Breadcrumb>
                <Breadcrumb>{id}</Breadcrumb>
            </BreadcrumbGroup>
            {
                alertMessage && (
                    <Alert
                        type={alertMessage.type}
                        title={alertMessage.title}
                        onClose={() => {
                            setAlertMessage(undefined);
                        }}
                    >
                        {alertMessage.message}
                    </Alert>
                )
            }

            <Row alignmentHorizontal={'justify'}>
                <PageHeader headerText={`${configuration.resourceName()}: ${id}`}/>
                <Row>
                    <Button onClick={() => configuration.navigateEditResource(resource)}>Update</Button>
                    <Button onClick={() => setDeleteConfirmationModalOpen(true)}>Delete</Button>
                </Row>
            </Row>
            {loading ? <LoadingMessage size='medium' message='Loading...' alignmentHorizontal='start'/> : null}

            {!configuration.viewResourceConfiguration ?
                <KeyValueDisplay keyId='resource-view' columns={getColumns(getFields(resource), resource)}/> :
                <Box type={'outline'} spacingInset={'400'}>
                    {!loading && viewConfiguration ?
                        Object.entries(viewConfiguration).map(([key, config], ii) => (
                            <div style={{paddingTop: '20px'}} key={key}>
                                <Column key={key + key} spacing={'400'} overflowX={'auto'} overflowY={'auto'}>
                                    {displayAttribute(key, resource)}
                                </Column>
                            </div>
                        )) : undefined}
                </Box>
            }
            <Modal
                title='Resource Deletion Confirmation'
                open={deleteConfirmationOpen}
                onClose={() => setDeleteConfirmationModalOpen(false)}
                scrollContainer='viewport'
                closeLabel='Close'
                aria-describedby='modal-description'
            >
                <Column>
                    <Text type={'h100'} id='modal-description'>Are you sure you want to delete this resource?</Text>
                    <Row>
                        <Button type={'secondary'} onClick={() => setDeleteConfirmationModalOpen(false)}>
                            No
                        </Button>
                        <Button data-testid={'ConfirmDelete'}
                            onClick={() => deleteResource()}
                            type={'primary'}>
                            Yes
                        </Button>
                    </Row>
                </Column>
            </Modal>
        </Column>
    );
};
