/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Sheet from '@amzn/meridian/sheet';
import Text from '@amzn/meridian/text';
import closeTokens from '@amzn/meridian-tokens/base/icon/close-medium';
import cogTokens from '@amzn/meridian-tokens/base/icon/cog';
import {useBundle} from '@amzn/react-arb-tools';
import _ from 'lodash';
import React from 'react';

import {AppContext} from '../../../app';

const UserSheet = () => {

    const [commonBundle] = useBundle('common.common');

    const [componentBundle] = useBundle('UserSheet.UserSheet');

    const FEEDBACK_LINK = 'https://sim.amazon.com/issues/create?assignedFolder=6ea41fec-fb21-493b-b09b-09089ee86969' +
        '&title=%5BID4+Portal%5D+-+%5BFeedback%5D+-+%3CDescribe+feedback+here%3E+' +
        `&description=**Feedback**%3A%0AEnter+your+feedback+here...%0A%0AURL%3A+${window.location.href}` +
        '&descriptionContentType=text%2Famz-markdown-sim' +
        '&labels%5B0%5D%5Bid%5D=cc0622d0-392d-488a-ba33-bb4ce2b3b419&extensions%5Btt%5D%5Bcategory%5D=';

    const BUG_LINK = 'https://sim.amazon.com/issues/create?assignedFolder=6ea41fec-fb21-493b-b09b-09089ee86969' +
        '&title=%5BID4+Portal%5D+-+%5BBug%5D+-+%3CDescribe+bug+here%3E+&description=**Bug**%3A%0' +
        `AEnter+your+bug+here...%0A%0A**Steps+to+reproduce**%3A%0A1.%0A2.%0A3.%0A%0AURL%3A+${window.location.href}` +
        '&descriptionContentType=text%2Famz-markdown-sim&labels%5B0%5D%5Bid%5D=bcc9955f-4e59-4eae-9c7e-adda58cef9e6' +
        '&labels%5B0%5D%5Bid%5D=cc0622d0-392d-488a-ba33-bb4ce2b3b419&labels%5B1%5D%5Bid%5D=8b04ed83-94b3-40db-afff-6f5abc67269f' +
        '&extensions%5Btt%5D%5Bcategory%5D=';

    const [open, setOpen] = React.useState(false);

    const onClickOpen = React.useCallback(() => setOpen(true), []);

    const onClickClose = React.useCallback(() => setOpen(false), []);

    const onClose = React.useCallback(() => setOpen(false), []);

    const appContext = React.useContext(AppContext);

    /**
     * Renders the permission groups section of the sheet.
     *
     * @returns {React.ReactElement[] | React.ReactElement} permissions groups list
     */
    function renderPermissionGroups(): React.ReactElement[] | React.ReactElement {
        // Only render if the user has at least one custom claim
        const shouldRenderPermissionsGroups = appContext.customClaims
            && _.some(Object.entries(appContext.customClaims), ([_, canUserUnlock]) => canUserUnlock === 'true');
        if (shouldRenderPermissionsGroups) {
            return (
                <>
                    <Heading level={4}>{componentBundle?.getMessage('user-sheet-header') ?? ''}</Heading>
                    {
                        _.map(appContext.customClaims, (canUserUnlock, lockName) => {
                            return canUserUnlock === 'true' && <Text color='secondary' key={lockName}>{lockName}</Text>;
                        })
                    }
                </>
            );
        } else {
            return <div />;
        }
    }


    return (
        <Row width='100%' widths={['fill', 'fit']}>
            <Row alignmentHorizontal='center'>
                <Button type='icon' size='small' onClick={onClickOpen} label={commonBundle?.getMessage('settings') ?? ''}>
                    <Icon tokens={cogTokens}>{commonBundle?.getMessage('settings') ?? ''}</Icon>
                </Button>
            </Row>
            <Sheet type='overlay' side='end' open={open} onClose={onClose} className='user-sheet'>
                <Column spacing='400'>
                    <Column>
                        <Row alignmentHorizontal='end'>
                            <Button onClick={onClickClose} type='icon' label={commonBundle?.getMessage('close') ?? ''}>
                                <Icon tokens={closeTokens}>{commonBundle?.getMessage('close') ?? ''}</Icon>
                            </Button>
                        </Row>
                        <Heading level={3}>{commonBundle?.getMessage('settings') ?? ''}</Heading>
                        {renderPermissionGroups()}
                    </Column>
                    <Column>
                        <Heading level={3}>{componentBundle?.getMessage('links') ?? ''}</Heading>
                        <Link href={FEEDBACK_LINK} target='_blank'>
                            {componentBundle?.getMessage('submit-feedback') ?? ''}
                        </Link>
                        <Link href={BUG_LINK} target='_blank'>
                            {componentBundle?.getMessage('report-bug') ?? ''}
                        </Link>
                        <Link href='https://w.amazon.com/bin/view/Amazon-Robotics/GSS/TOOLS/ID4/' target='_blank'>
                            {componentBundle?.getMessage('wiki') ?? ''}
                        </Link>
                    </Column>
                </Column>
            </Sheet>
        </Row>
    );
};

export default UserSheet;
