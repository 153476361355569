/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {SimConfiguration} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration';

export const validateSimConfiguration = (config: SimConfiguration): { valid: boolean, reason?: string } => {
    if (!config) {
        return { valid: false, reason: 'Template is not filled out' };
    }
    if (!config.titleTemplate) {
        return { valid: false, reason: 'Title template is required' };
    }
    if (!config.descriptionTemplate) {
        return { valid: false, reason: 'Description template is required' };
    }
    if (config.assignee) {
        if (!config.assignee.namespace) {
            return { valid: false, reason: 'Assignee namespace is required' };
        }
        if (!config.assignee.value) {
            return { valid: false, reason: 'Assignee value is required' };
        }
    }
    if (config.assignedGroup) {
        if (!config.assignedGroup.namespace) {
            return { valid: false, reason: 'Assigned group namespace is required' };
        }
        if (!config.assignedGroup.value) {
            return { valid: false, reason: 'Assigned group value is required' };
        }
    }
    if (config.requester) {
        if (!config.requester.namespace) {
            return { valid: false, reason: 'Requester namespace is required' };
        }
        if (!config.requester.value) {
            return { valid: false, reason: 'Requester value is required' };
        }
    }
    if (!config.notificationGrouping) {
        return { valid: false, reason: 'Notification grouping is required' };
    }
    if (!config.dedupeStrategy) {
        return { valid: false, reason: 'Deduplication strategy is required' };
    }
    if (!config.expirationStrategy) {
        return { valid: false, reason: 'Expiration strategy is required' };
    }

    if (config.problemResolver === undefined){
        return { valid: false, reason: 'Problem resolver is required' };
    }

    if (!config.closureStrategy) {
        return { valid: false, reason: 'Closure strategy is required' };
    }

    return { valid: true };
};
