/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {Clusivity} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/common';
import {SimConfiguration} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration';
import {
    DedupeStrategy,
    EscalationMap,
    NotificationGrouping,
    ProblemFilter,
    ProblemFilterType,
    StatusChangeStrategy
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import {
    SimAccessLevel,
    SimAutoUpgradePolicy,
    SimCategorizationEntry,
    SimIdentity,
    SimTag
} from '@amzn/id4-mothership/com/amazon/id4notificationservice/model/types/sim';
import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Box from '@amzn/meridian/box';
import Breadcrumb, {BreadcrumbGroup} from '@amzn/meridian/breadcrumb';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import minusCircleTokens from '@amzn/meridian-tokens/base/icon/minus-circle';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import cron from 'cron-validate';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {AppContext} from '../../../app';
import useWebhookTemplate from '../../../hooks/templates/use-webhook-template';
import useWorkOrderTemplate from '../../../hooks/templates/use-work-order-template';
import {useSearchSelect} from '../../../hooks/use-search-select';
import {selectSelectedRegion} from '../../../state/app/appSlice';
import {getCronError} from '../../../utility/cron-utility';
import {
    getReportConfiguration,
    listProblemFinderRegistrations,
    putReportConfiguration
} from '../../../utility/id4-mothership-client';
import {validateSimConfiguration} from '../../../utility/validate-report-configuration-template';
import SimTemplate from '../../ConfigurationTemplates/SimTemplate';
import {CreationProps} from '../../Inputs/CreationProps';
import SchedulingInput from '../../Inputs/SchedulingInput';
import {RouteName} from '../../Routing/RoutingPage';
import ControlledExpander from '../../utility-views/ControlledExpander';
import LoadingButton from '../../utility-views/LoadingButton';

const CreateReportConfiguration: React.FC<CreationProps> = ({inEditMode}) => {

    const {webStageConfig} = React.useContext(AppContext);

    const selectedRegion = useSelector(selectSelectedRegion);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    let {reportConfigurationId} = useParams();

    const [submitted, setSubmitted] = useState<boolean>(false);

    const [alertMessage, setAlertMessage] = useState<{
        type: AlertProps['type'],
        title?: AlertProps['title'],
        message: string,
        link?: { text: string, href: string }
    }>(undefined);

    const [currentProblemFinderIds, setCurrentProblemFinderIds] = React.useState<string[]>([]);
    const [loadingCurrentProblemFinderIds, setLoadingCurrentProblemFinderIds] = React.useState<boolean>(true);

    // Defaulting to uuid for all new report configurations
    const [reportConfigurationIdToUse, setReportConfigurationIdToUse] = React.useState<string>(inEditMode ? reportConfigurationId : undefined);
    const [executionState, setReportConfigExecutionState] = React.useState<string>(undefined);

    const [permissionsTeam, setPermissionsTeam] = React.useState<string>(undefined);

    const [problemFinderIds, setProblemFinderIds, ProblemFinderIdsSelect] = useSearchSelect({
        label: 'Problem finder identifiers',
        placeholder: 'Select problem finder identifiers',
        options: currentProblemFinderIds.map(id => ({label: id, value: id})),
        isLoading: loadingCurrentProblemFinderIds,
        loadingMessage: 'Loading problem finders...',
        allowMultiSelect: true,
    });

    const [problemFilters, setProblemsFilters] = React.useState<ProblemFilter[]>([]);

    const [webhookConfiguration, resetWebhookInput, setWebhookInput, webhookErrorOrIncomplete, WebhookTemplate] = useWebhookTemplate();

    const [workOrderConfiguration, resetWorkOrderInput, setWorkOrderInput, workOrderErrorOrIncomplete, WorkOrderTemplate] = useWorkOrderTemplate();

    const [simConfiguration, setSimConfiguration] = React.useState<SimConfiguration>(undefined);

    const [cronInput, setCronInput] = React.useState<string>(undefined);
    const cronError = useMemo(() => getCronError(cronInput), [cronInput]);
    const [enabled, setEnabled] = React.useState<boolean>(false);

    /**
     * Effect which will get the current report configurations.
     */
    React.useEffect(() => {
        // Initially clear the problem finder registrations when the region changes
        setCurrentProblemFinderIds([]);
        setLoadingCurrentProblemFinderIds(true);

        const fetchProblemFinderRegistrations = async () => {
            const registrations = await listProblemFinderRegistrations(webStageConfig, selectedRegion);
            setCurrentProblemFinderIds(registrations.map(reg => reg.problemFinderId));
        };

        fetchProblemFinderRegistrations()
            .catch(err => {
                setAlertMessage({
                    type: 'error',
                    message: err.message,
                    title: 'Error loading known problem finder identifiers.'
                });
                console.error(err);
            })
            .finally(() => setLoadingCurrentProblemFinderIds(false));
    }, [selectedRegion]);

    /**
     * Effect which will retrieve the problem finder registration from the URL param ID.
     */
    React.useEffect(() => {
        if (inEditMode) {
            const fetchReportConfiguration = async () => {
                const existingRegistration = await getReportConfiguration(webStageConfig, selectedRegion, {
                    reportConfigurationId: reportConfigurationId
                });
                setProblemFinderIds(existingRegistration.problemFinderIds);
                setPermissionsTeam(existingRegistration.permissionsTeam);
                setProblemsFilters(existingRegistration.problemFilters);
                setWebhookInput(existingRegistration.notifications.webhookConfiguration);
                setWorkOrderInput(existingRegistration.notifications.workOrderConfiguration);
                setSimConfiguration(existingRegistration.notifications.simConfiguration);
                setEnabled(existingRegistration.enabled);
                setReportConfigExecutionState(existingRegistration.reportConfigExecutionState);
                setCronInput(existingRegistration.scheduleCronExpression);
            };

            fetchReportConfiguration()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading report configuration.'
                    });
                    console.error(err);
                });
        }
    }, [inEditMode, selectedRegion]);

    /**
     * Are all the required inputs filled out to create a report configuration?
     *
     * @returns {boolean} true if all the required inputs filled out to create a report configuration
     */
    function isFormComplete(): boolean {
        return !!reportConfigurationIdToUse
            && !!permissionsTeam
            && problemFinderIds.length > 0
            && (problemFilters.length === 0 || problemFilters.every(pf => pf.value !== undefined && pf.type !== undefined && pf.clusivity !== undefined))
            && cronInput !== undefined && cron(cronInput).isValid()
            && (!webhookErrorOrIncomplete || !workOrderErrorOrIncomplete || validateSimConfiguration(simConfiguration).valid);
    }

    /**
     * Set the problem filter result for the given index.
     *
     * @param problemFilter problem filter
     * @param idx index
     */
    function setProblemFilterResult(problemFilter: ProblemFilter, idx: number) {
        setProblemsFilters(prevState => {
            prevState[idx] = problemFilter;
            return prevState;
        });
    }

    /**
     * Clears the page inputs.
     */
    function clearInputs(): void {
        setReportConfigurationIdToUse(undefined);
        setPermissionsTeam(undefined);
        setProblemFinderIds([]);
        setProblemsFilters([]);
        setEnabled(false);
        setCronInput(undefined);
        resetWebhookInput();
        resetWorkOrderInput();
        setSimConfiguration(undefined);
    }

    /**
     * Callback for submitting a report configuration.
     */
    function onSubmit(): void {
        setSubmitted(true);
        putReportConfiguration(webStageConfig, selectedRegion, {
            reportConfigurationId: reportConfigurationIdToUse,
            reportConfigurationDetails: {
                enabled: enabled,
                scheduleCronExpression: cronInput,
                problemFinderIds: problemFinderIds as string[],
                problemFilters: problemFilters,
                notifications: {
                    webhookConfiguration: !webhookErrorOrIncomplete ? webhookConfiguration : undefined,
                    workOrderConfiguration: !workOrderErrorOrIncomplete ? workOrderConfiguration : undefined,
                    simConfiguration: validateSimConfiguration(simConfiguration).valid ? simConfiguration : undefined
                },
                permissionsTeam: permissionsTeam
            }
        })
            .then(response => {
                // TODO: add response to list of reports
                setAlertMessage({
                    type: 'success',
                    message: `Successfully ${inEditMode ? 'updated' : 'created'} report configuration: `,
                    link: {
                        text: response.reportConfiguration.reportConfigurationId,
                        href: `${RouteName.REPORT_CONFIGURATIONS}/${response.reportConfiguration.reportConfigurationId}`
                    }
                });
                clearInputs();
            })
            .catch(err => {
                setAlertMessage({
                    type: 'error',
                    message: err.message,
                    title: 'Error creating report configuration'
                });
            })
            .finally(() => {
                setSubmitted(false);
            });
    }

    return (
        <Column alignmentHorizontal='start' spacing='500'>
            <BreadcrumbGroup>
                <Breadcrumb href={RouteName.REPORT_CONFIGURATIONS}>Report Configurations</Breadcrumb>
                <Breadcrumb>{inEditMode ? reportConfigurationId : 'Creating'}</Breadcrumb>
            </BreadcrumbGroup>
            {
                alertMessage && (
                    <Alert
                        type={alertMessage.type}
                        title={alertMessage.title}
                        onClose={() => {
                            setAlertMessage(undefined);
                            setSubmitted(false);
                        }}
                    >
                        {alertMessage.message}
                        {
                            alertMessage.link && (
                                <Link onClick={() => navigate(alertMessage.link.href)}>{alertMessage.link.text}</Link>
                            )
                        }
                    </Alert>
                )
            }
            {
                inEditMode && (
                    <Link href={RouteName.REPORT_CONFIGURATIONS + '/create'}>
                        Not looking to update an existing configuration? Click here to create one instead.
                    </Link>
                )
            }
            <Column>
                <Box type='outline' spacingInset='400'>
                    <Input
                        id='report-config-id-input'
                        value={reportConfigurationIdToUse}
                        onChange={(val) => setReportConfigurationIdToUse(val)}
                        type='text'
                        error={reportConfigurationIdToUse === '' || !new RegExp('[A-Z]([A-Z0-9]*[a-z][a-z0-9]*[A-Z]|[a-z0-9]*[A-Z][A-Z0-9]*[a-z])[A-Za-z0-9]*').test(reportConfigurationIdToUse)}
                        label={`Report Configuration ID *`}
                        helperText={'Unique Identifier for Report Configuration'}
                        disabled={inEditMode}
                        width='100%'
                    />
                </Box>
                <Box type='outline' spacingInset='400'>
                    <Column width='100%' alignmentHorizontal='start'>
                        <Text type='h300'>Problem Filtering</Text>
                        {ProblemFinderIdsSelect}
                        <Column width='100%' alignmentHorizontal='start'>
                            {
                                problemFilters.map((pf, ii) => {
                                    return (
                                        <ProblemFilterInput
                                            key={`problem-filter-${ii}`}
                                            setProblemFilterResult={setProblemFilterResult}
                                            idx={ii}
                                            onRemoveClicked={(index: number) => {
                                                setProblemsFilters(prevState => {
                                                    return prevState.splice(0, index).concat(prevState.splice(index + 1, prevState.length));
                                                });
                                            }}
                                        />
                                    );
                                })
                            }
                            <Button type='secondary' onClick={() => {
                                setProblemsFilters(prevState => [...prevState, {
                                    value: undefined,
                                    type: undefined,
                                    clusivity: undefined
                                }]);
                            }}>
                                <Icon tokens={plusCircleTokens}>Add filter</Icon>Add filter
                            </Button>
                        </Column>
                    </Column>
                </Box>
                <Box type='outline' spacingInset='400'>
                    <Column spacing='450'>
                        <Text type='h300'>Permissions</Text>
                        <Input
                            id='permissions-team-input'
                            value={permissionsTeam}
                            onChange={setPermissionsTeam}
                            type='text'
                            helperText='A team from https://permissions.amazon.com/ which will control who can edit this report configuration'
                            label='Team'
                            width='100%'
                            error={permissionsTeam === ''}
                            errorMessage={permissionsTeam === '' && 'Permissions team cannot be empty'}
                        />
                    </Column>
                </Box>
                <Row alignmentVertical='stretch' widths='fill' spacing='500' wrap='down'>
                    <Box type='outline' spacingInset='400' minWidth={400}>
                        <Column spacing='450'>
                            <Text type='h300'>Message Configuration</Text>
                            <Text type='b100' color='secondary'>Note that you are not required to fill out all of the message
                                configurations.</Text>
                            <ControlledExpander title='Webhook Configuration'>
                                {WebhookTemplate}
                            </ControlledExpander>
                            <ControlledExpander title='SIM Configuration'>
                                <SimTemplate
                                    simConfiguration={simConfiguration}
                                    setTitleTemplate={(titleTemplate: string) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        titleTemplate
                                    }))}
                                    setDescriptionTemplate={(descriptionTemplate: string) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        descriptionTemplate
                                    }))}
                                    setAssignee={(assignee: SimIdentity) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        assignee
                                    }))}
                                    setAssignedGroup={(assignedGroup: SimIdentity) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        assignedGroup
                                    }))}
                                    setRequester={(requester: SimIdentity) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        requester
                                    }))}
                                    setCategorization={(categorization: SimCategorizationEntry[]) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        categorization
                                    }))}
                                    setEscalationMap={(escalationMap: EscalationMap) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        escalationMap
                                    }))}
                                    setEstimatedStartTime={(estimatedStartTime: number) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        estimatedStartTime
                                    }))}
                                    setEstimatedCompletionTime={(estimatedCompletionTime: number) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        estimatedCompletionTime
                                    }))}
                                    setTags={(tags: SimTag[]) => setSimConfiguration(prevState => ({...prevState, tags}))}
                                    setAccessLevel={(accessLevel: SimAccessLevel) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        accessLevel
                                    }))}
                                    setAutoUpgrade={(autoUpgrade: SimAutoUpgradePolicy) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        autoUpgrade
                                    }))}
                                    setConcerning={(concerning: string) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        concerning
                                    }))}
                                    setRelationshipToConcerning={(relationshipToConcerning: string) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        relationshipToConcerning
                                    }))}
                                    setNotificationGrouping={(notificationGrouping: NotificationGrouping) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        notificationGrouping
                                    }))}
                                    setDedupeStrategy={(dedupeStrategy: DedupeStrategy) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        dedupeStrategy
                                    }))}
                                    setExpirationStrategy={(expirationStrategy: StatusChangeStrategy) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        expirationStrategy
                                    }))}
                                    setClosureStrategy={(closureStrategy: StatusChangeStrategy) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        closureStrategy
                                    }))}
                                    setProblemResolver={(problemResolver: boolean) => setSimConfiguration(prevState => ({
                                        ...prevState,
                                        problemResolver
                                    }))}
                                />
                            </ControlledExpander>
                            <ControlledExpander title='Work Order Configuration'>
                                {WorkOrderTemplate}
                            </ControlledExpander>
                        </Column>
                    </Box>
                    <Box type='outline' spacingInset='400' minWidth={400}>
                        <Column alignmentHorizontal='start'>
                            <SchedulingInput
                                enabled={enabled}
                                cronError={cronError}
                                setEnabled={setEnabled}
                                cronExpression={cronInput}
                                setCronExpression={setCronInput}
                                enabledText='Enable report configuration?'
                                secondaryText='Select a fixed period to run the report configuration. Alternatively, define your own Cron expression.'
                            />
                        </Column>
                    </Box>
                </Row>
            </Column>
            <LoadingButton
                text={!inEditMode ? 'Create' : 'Update'}
                isLoading={submitted}
                disabled={!isFormComplete() || submitted}
                onClick={onSubmit}
            />
        </Column>
    );
};

const ProblemFilterInput = ({setProblemFilterResult, idx, onRemoveClicked}: {
    setProblemFilterResult: (problemFilter: ProblemFilter, idx: number) => void
    idx: number
    onRemoveClicked: (idx: number) => void
}) => {

    const [problemFilterValue, setProblemFilterValue] = React.useState<string>(undefined);

    const [problemFilterType, , ProblemFilterTypeSelect] = useSearchSelect({
        label: 'Filter type',
        placeholder: 'Select filter type',
        options: Object.entries(ProblemFilterType).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading problem filter types...',
        width: '25%'
    });

    const [problemFilterClusivity, , ProblemFilterClusivitySelect] = useSearchSelect({
        label: 'Filter clusivity',
        placeholder: 'Select filter clusivity',
        options: Object.entries(Clusivity).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading problem filter clusivities...',
        width: '25%'
    });

    /**
     * Effect which will invoke a callback to set the problem filter result.
     */
    React.useEffect(() => {
        setProblemFilterResult({
            value: problemFilterValue,
            type: problemFilterType as ProblemFilterType,
            clusivity: problemFilterClusivity as Clusivity
        }, idx);
    }, [problemFilterValue, problemFilterType, problemFilterClusivity]);

    return (
        <Row alignmentHorizontal='start' alignmentVertical='bottom' width='100%'>
            <Input
                label='Filter value'
                value={problemFilterValue}
                onChange={setProblemFilterValue}
                placeholder='Enter value'
            />
            {ProblemFilterTypeSelect}
            {ProblemFilterClusivitySelect}
            <Button onClick={() => onRemoveClicked(idx)} type='secondary'>
                <Icon tokens={minusCircleTokens}>Remove</Icon>Remove
            </Button>
        </Row>
    );
};

export default CreateReportConfiguration;
