/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {DedupeStrategy} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import React from 'react';

import SearchSelect from '../utility-views/SearchSelect';

export type DedupeStrategyInputProps = {
    dedupeStrategyValue: DedupeStrategy
    setDedupeStrategyValue: (arg0: DedupeStrategy) =>  void
}

const DedupeStrategyInput = ({dedupeStrategyValue, setDedupeStrategyValue}: DedupeStrategyInputProps) => {
    return (
        <SearchSelect
            label='Deduplication strategy'
            placeholder='Select dedeuplication strategy'
            options={Object.entries(DedupeStrategy).map(([label, value]) => ({ label, value }))}
            isLoading={false}
            loadingMessage='Loading strategies...'
            selectedValue={dedupeStrategyValue}
            setSelectedValue={setDedupeStrategyValue}
        />
    );
};

export default DedupeStrategyInput;
