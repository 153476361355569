/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Menu, {MenuItem} from '@amzn/meridian/menu';
import globeTokens from '@amzn/meridian-tokens/base/icon/globe';
import {useLocalizationContext} from '@amzn/react-arb-tools';
import _ from 'lodash';
import React from 'react';

import {localizationContextBuilder} from '../../../app';
import {getLocalStorageItem, LocalStorageKeys, setLocalStorageItem} from '../../../utility/local-storage';

type LanguageOption = { label: string, value: string };

export const DEFAULT_LANGUAGE_VALUE = 'en-US';

const LanguageMenu = () => {

    const { setLocalizationContext } = useLocalizationContext();

    // This language list should be kept up-to-date with the operations family list
    // https://w.amazon.com/bin/view/INTech/Panther/I18nFamily//#Hoperations
    const languageList: LanguageOption[] = [
        { label: 'Afrikaans', value: 'af-ZA' },
        { label: 'العَرَبِيَّة‎', value: 'ar-AE' },
        { label: 'العَرَبِيَّة‎', value: 'ar-SA' },
        { label: 'български', value: 'bg-BG' },
        { label: 'বাংলা', value: 'bn-IN' },
        { label: 'Čeština', value: 'cs-CZ' },
        { label: 'Deutsch', value: 'de-DE' },
        { label: 'English (United States)', value: DEFAULT_LANGUAGE_VALUE },
        { label: 'English (UAE)', value: 'en-AE' },
        { label: 'English (Australia)', value: 'en-AU' },
        { label: 'English (Canada)', value: 'en-CA' },
        { label: 'English (Great Britain)', value: 'en-GB' },
        { label: 'English (India)', value: 'en-IN' },
        { label: 'English (Singapore)', value: 'en-SG' },
        { label: 'Español (Argentina)', value: 'es-AR' },
        { label: 'Español (Chile)', value: 'es-CL' },
        { label: 'Español (Colombia)', value: 'es-CO' },
        { label: 'Español (España)', value: 'es-ES' },
        { label: 'Español (Mexico)', value: 'es-MX' },
        { label: 'Español (Estados Unidos)', value: 'es-US' },
        { label: 'دری', value: 'fa-AF' },
        { label: 'Français (Canada)', value: 'fr-CA' },
        { label: 'Français (France)', value: 'fr-FR' },
        { label: 'ગુજરાતી', value: 'gu-IN' },
        { label: 'هَرْشَن هَوْسَ', value: 'ha-NG' },
        { label: 'עִבְרִית', value: 'he-IL' },
        { label: 'हिन्दी', value: 'hi-IN' },
        { label: 'kreyòl ayisyen', value: 'ht-HT' },
        { label: 'magyar', value: 'hu-HU' },
        { label: 'Indonesia', value: 'id-ID' },
        { label: 'Italiano', value: 'it-IT' },
        { label: '日本語', value: 'ja-JP' },
        { label: 'ಕನ್ನಡ', value: 'kn-IN' },
        { label: '한국어', value: 'ko-KR' },
        { label: 'Lietuvių', value: 'lt-LT' },
        { label: 'latviešu valoda', value: 'lv-LV' },
        { label: 'മലയാളം', value: 'ml-IN' },
        { label: 'मराठी', value: 'mr-IN' },
        { label: 'မြန်မာစာ', value: 'my-MM' },
        { label: 'नेपाली', value: 'ne-NP' },
        { label: 'nederlands', value: 'nl-NL' },
        { label: 'ਪੰਜਾਬੀ', value: 'pa-IN' },
        { label: 'Polski', value: 'pl-PL' },
        { label: 'پښتو', value: 'ps-AF' },
        { label: 'Português (Brasil)', value: 'pt-BR' },
        { label: 'Português (Portugal)', value: 'pt-PT' },
        { label: 'Română', value: 'ro-RO' },
        { label: 'Русский', value: 'ru-RU' },
        { label: 'Slovenčina', value: 'sk-SK' },
        { label: 'slovenščina', value: 'sl-SI' },
        { label: 'Somali', value: 'so-SO' },
        { label: 'svenska', value: 'sv-SE' },
        { label: 'தமிழ்', value: 'ta-IN' },
        { label: 'తెలుగు', value: 'te-IN' },
        { label: 'Tagalog', value: 'tl-PH' },
        { label: 'Türkçe', value: 'tr-TR' },
        { label: 'Українська', value: 'uk-UA' },
        { label: 'اُردُو', value: 'ur-PK' },
        { label: 'tiếng việt', value: 'vi-VN' },
        { label: 'isiXhosa', value: 'xh-ZA' },
        { label: 'Èdè Yorùbá', value: 'yo-NG' },
        { label: '汉语', value: 'zh-CN' },
        { label: '漢語', value: 'zh-TW' },
        { label: 'isiZulu', value: 'zu-ZA' },
    ];

    const selectedLanguageValue = getLocalStorageItem(LocalStorageKeys.ID4_LANGUAGE) ?? DEFAULT_LANGUAGE_VALUE;
    const selectedLanguageLabel = languageList.find(option => option.value === selectedLanguageValue).label;

    const [languageLabel, setLanguageLabel] = React.useState(selectedLanguageLabel);
    
    const buttonRef = React.useRef();
    const [open, setOpen] = React.useState(false);
    const onClickButton = React.useCallback(() => setOpen(!open), []);
    const onClose = React.useCallback(() => setOpen(false), []);

    const setChosenLanguage = (option: LanguageOption) => {
        // Store the language in local storage so it can be retrieved next time the page loads
        setLocalStorageItem(LocalStorageKeys.ID4_LANGUAGE, option.value);
        // Store is a React state variable in order to re-render the component so it shows the changed language
        setLanguageLabel(option.label);
        // Update the ARB context with the new locale value (this will load the new the language files)
        setLocalizationContext(
            localizationContextBuilder
                .withLocale(option.value)
                .build()
        );
    };

    return (
        <React.Fragment>
            <Button type='icon' size='small' onClick={onClickButton} ref={buttonRef}>
                <Icon tokens={globeTokens}>Language</Icon>
                {languageLabel}
            </Button>
            <Menu anchorNode={buttonRef.current} open={open} position='bottom' onClose={onClose} minWidth={175}>
                {
                    _.sortBy(languageList, 'value').map(lang => {
                        return (
                            <MenuItem
                                key={`region-menu-item-${lang.value}`}
                                onClick={() => setChosenLanguage(lang)}
                            >
                                {lang.label}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </React.Fragment>
    );
};

export default LanguageMenu;
