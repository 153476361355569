/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {NotificationGrouping} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import React from 'react';

import SearchSelect from '../utility-views/SearchSelect';

export type NotificationGroupingInputProps = {
    notificationGroupingValue: NotificationGrouping
    setNotificationGroupingValue: (arg0: NotificationGrouping) => void
}

const NotificationGroupingInput = ({notificationGroupingValue, setNotificationGroupingValue}: NotificationGroupingInputProps) => {
    return (
        <SearchSelect
            label='Notification grouping'
            placeholder='Select notification grouping option'
            options={Object.entries(NotificationGrouping).map(([label, value]) => ({ label, value }))}
            isLoading={false}
            loadingMessage='Loading notification grouping options...'
            selectedValue={notificationGroupingValue}
            setSelectedValue={setNotificationGroupingValue}
        />
    );
};

export default NotificationGroupingInput;
