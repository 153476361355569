/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {
    DedupeStrategy,
    NotificationGrouping,
    StatusChangeStrategy,
    WebhookConfiguration
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import {WebhookRegistrationSet} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/scheduler';
import Column from '@amzn/meridian/column';
import Textarea from '@amzn/meridian/textarea';
import React from 'react';
import {useSelector} from 'react-redux';

import {AppContext} from '../../app';
import SearchSelect from '../../components/utility-views/SearchSelect';
import {selectSelectedRegion} from '../../state/app/appSlice';
import {InputError} from '../../types/input-error';
import {listWebhookSets} from '../../utility/id4-mothership-client';
import {useSearchSelect} from '../use-search-select';

/**
 * Hook to provide a webhook message template.
 *
 * @returns {[WebhookConfiguration, () => void, (arg0: WebhookConfiguration) => void, boolean, React.ReactElement]} configuration object, reset input function, set input function, input error or incomplete, webhook template component
 */
const useWebhookTemplate = (): [WebhookConfiguration, () => void, (arg0: WebhookConfiguration) => void, boolean, React.ReactElement] => {

    const {webStageConfig} = React.useContext(AppContext);

    const selectedRegion = useSelector(selectSelectedRegion);

    const [messageTemplate, setMessageTemplate] = React.useState<string>(undefined);

    const [webhookRegistrationSetIds, setWebhookRegistrationSetIds] = React.useState<string[]>([]);
    const [webhookSets, setWebhookSets] = React.useState<WebhookRegistrationSet[]>([]);

    const [notificationGrouping, setNotificationGrouping, NotificationGroupingSelect] = useSearchSelect({
        label: 'Notification grouping',
        placeholder: 'Select notification grouping option',
        options: Object.entries(NotificationGrouping).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading notification grouping options...',
    });

    const [dedupeStrategy, setDedupeStrategy, DedupeStrategySelect] = useSearchSelect({
        label: 'Deduplication strategy',
        placeholder: 'Select deduplication strategy',
        options: Object.entries(DedupeStrategy).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading strategies...',
    });

    const [expirationStrategy, setExpirationStrategy, ExpirationStrategy] = useSearchSelect({
        label: 'Expiration strategy',
        placeholder: 'Select expiration strategy',
        options: Object.entries(StatusChangeStrategy).map(([label, value]) => ({label: label, value: value})),
        isLoading: false,
        loadingMessage: 'Loading strategies...',
    });

    const [closureStrategy, setClosureStrategy, ClosureStrategy] = useSearchSelect({
        label: 'Closure strategy',
        placeholder: 'Select closure strategy',
        options: Object.entries(StatusChangeStrategy).map(([label, value]) => ({label: label, value: value})),
        isLoading: false,
        loadingMessage: 'Loading strategies...',
    });

    React.useEffect(() => {
        const getWebhookSets = async () => {
            const webhookSets = await listWebhookSets(webStageConfig, selectedRegion);
            console.log('Sets: {}', webhookSets);
            setWebhookSets(webhookSets);
        };

        getWebhookSets().catch(err => {
            console.error(err);
        });


    }, [selectedRegion]);

    /**
     * Evaluates the webhook message template.
     *
     * @returns {InputError} error and message
     */
    function evaluateMessageTemplate(): InputError {
        if (messageTemplate === '') {
            return {
                error: true,
                errorMessage: 'Webhook message cannot be empty'
            };
        } else {
            return {
                error: false,
                errorMessage: ''
            };
        }
    }

    /**
     * Validates the inputs.
     *
     * @returns {boolean} true if all are valid
     */
    function areAllInputsValid(): boolean {
        return messageTemplate !== undefined && !evaluateMessageTemplate().error
            && webhookRegistrationSetIds !== undefined
            && notificationGrouping !== undefined
            && dedupeStrategy !== undefined
            && expirationStrategy !== undefined
            && closureStrategy !== undefined;
    }

    /**
     * Reset the inputs.
     */
    function resetInput() {
        setMessageTemplate(undefined);
        setWebhookRegistrationSetIds(undefined);
        setNotificationGrouping(undefined);
        setDedupeStrategy(undefined);
        setExpirationStrategy(undefined);
        setClosureStrategy(undefined);
    }

    /**
     * Sets the Slack input with a given configuration.
     *
     * @param configuration Slack configuration
     */
    function setWebhookInput(configuration: WebhookConfiguration) {
        if (configuration) {
            setMessageTemplate(configuration.messageTemplate);
            setWebhookRegistrationSetIds(configuration.webhookRegistrationSetIds);
            setNotificationGrouping(configuration.notificationGrouping);
            setDedupeStrategy(configuration.dedupeStrategy);
            setExpirationStrategy(configuration.expirationStrategy);
            setClosureStrategy(configuration.closureStrategy);
        }
    }

    const WebhookTemplate = (
        <Column width='100%'>
            <SearchSelect
                label={'Webhook Sets'}
                placeholder='Select'
                options={webhookSets.map(whs => {
                    return {label: whs.description, value: whs.webhookRegistrationSetId};
                })}
                isLoading={false}
                loadingMessage='Loading Values'
                selectedValue={webhookRegistrationSetIds}
                setSelectedValue={(value: any) => {
                    setWebhookRegistrationSetIds(value);
                }}
            />
            <Textarea
                value={messageTemplate}
                onChange={setMessageTemplate}
                placeholder={'Enter message'}
                label={'Webhook message'}
                {...evaluateMessageTemplate()}
            />
            {NotificationGroupingSelect}
            {DedupeStrategySelect}
            {ExpirationStrategy}
            {ClosureStrategy}
        </Column>
    );

    return [
        {
            expirationStrategy: expirationStrategy as StatusChangeStrategy,
            closureStrategy: closureStrategy as StatusChangeStrategy,
            notificationGrouping: notificationGrouping as NotificationGrouping,
            dedupeStrategy: dedupeStrategy as DedupeStrategy,
            messageTemplate,
            webhookRegistrationSetIds: webhookRegistrationSetIds ? webhookRegistrationSetIds : []
        },
        resetInput,
        setWebhookInput,
        !areAllInputsValid(),
        WebhookTemplate
    ];
};

export default useWebhookTemplate;
