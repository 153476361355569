/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Loader from '@amzn/meridian/loader';
import {LoaderSize} from '@amzn/meridian/loader/loader';
import Row from '@amzn/meridian/row';
import {RowAlignmentHorizontal, RowProps} from '@amzn/meridian/row/row';
import Text from '@amzn/meridian/text';
import {TextColor, TextType} from '@amzn/meridian/text/text';
import React from 'react';

export type LoadingMessageProps = {
    /**
     * Size of the spinner.
     */
    size: LoaderSize
    /**
     * Message to display next to the spinner.
     */
    message: string
    /**
     * Optional padding to add around the spinner and text.
     */
    spacingInset?: RowProps['spacingInset']
    /**
     * Optional text color.
     */
    textColor?: TextColor
    /**
     * Row alignment. Defaults to center.
     */
    alignmentHorizontal?: RowAlignmentHorizontal
}

const LoadingMessage = (props: LoadingMessageProps) => {

    /**
     * Determines the text type (size) based on the size prop.
     *
     * @returns {TextType} TextType
     */
    function determineTextType(): TextType {
        switch (props.size) {
            case 'small': {
                return 'b300';
            }
            case 'medium': {
                return 'b400';
            }
            case 'large': {
                return 'b500';
            }
        }
    }

    return (
        <Row alignmentHorizontal={props.alignmentHorizontal || 'center'} spacingInset={props.spacingInset ?? 'none'}>
            <Loader size={props.size} />
            <Text type={determineTextType()} color={props.textColor ?? 'primary'}>{props.message}</Text>
        </Row>
    );
};

export default LoadingMessage;
