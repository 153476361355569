/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import React from 'react';

import LoadingMessage from '../utility-views/LoadingMessage';

const LoadingPage = () => {

    /**
     * Quotes from the movie Independence Day which ID4 is named after. Will randomly be shown on the loading screen.
     */
    const movieQuotes = [
        'Welcome to Earth!',
        'We will not go quietly into the night! We will not vanish without a fight! We’re going to live on! We’re going to survive! Today we celebrate our Independence Day!',
        'I could\'ve been at a barbecue!',
        'You really think you can fly that thing?',
        'Look, I really don\'t think they flew 90 billion light years to come down here and start a fight.'
    ];

    const getRandomQuote = movieQuotes[Math.floor(Math.random() * movieQuotes.length)];

    return (
        <Column width='100%' height='100%' alignmentHorizontal='start' spacingInset='400'>
            <LoadingMessage size='large' spacingInset='300' message={`Loading ID4 web portal... ${getRandomQuote}`} />
        </Column>
    );
};

export default LoadingPage;
