/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Row from '@amzn/meridian/row';
import React from 'react';

const AppFooter = () => {
    const getYear = () => {
        const currentTime = new Date();
        return currentTime.getFullYear();
    };

    return (
        <Row
            spacingInset='300' alignmentHorizontal='start' alignmentVertical='center'
            backgroundColor='primary' type='outline'
            width='100%' widths='fill'
        >
            <Row alignmentHorizontal='start' />
            <Row alignmentHorizontal='center'>Amazon Robotics Signals Team</Row>
            <Row alignmentHorizontal='end'>©{getYear()} Amazon.com, Inc.</Row>
        </Row>
    );
};

export default AppFooter;
