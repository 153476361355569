/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {ProblemFinderOverride} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/override/types';
import {ScopeType} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {NavigateFunction} from 'react-router/dist/lib/hooks';
import {v4} from 'uuid';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {
    deleteProblemFinderOverride,
    getProblemFinderOverride,
    listProblemFinderOverrides,
    listProblemFinderRegistrations,
    putProblemFinderOverride
} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {IDeleteResource} from '../IDeleteResource';
import {EditResourceComponentType, EditResourceConfiguration, IPutResource} from '../IPutResource';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../ITableResource';
import {IViewResource} from '../IViewResource';


/**
 * The overrides' resource configuration.
 */
export class OverridesResourceConfiguration implements IPutResource<ProblemFinderOverride>, IViewResource<ProblemFinderOverride>, ITableResource<ProblemFinderOverride>, IDeleteResource<ProblemFinderOverride> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<ProblemFinderOverride[]> {
        return await listProblemFinderOverrides(webStageConfig, selectedRegion);
    }

    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<ProblemFinderOverride> {
        return getProblemFinderOverride(webStageConfig, region, {problemFinderOverrideId: resourceId});
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ProblemFinderOverride) {
        await deleteProblemFinderOverride(webStageConfig, region, {problemFinderOverrideId: resource.problemFinderOverrideId});
    }

    async putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ProblemFinderOverride): Promise<ProblemFinderOverride> {
        return await putProblemFinderOverride(webStageConfig, region,
            {
                problemFinderOverrideId: resource.problemFinderOverrideId,
                overrideDetails: {...resource}
            }
        ).then(response => response.override);
    }

    navigateCreateResource(): void {
        this.navigate(`${RouteName.OVERRIDES}/create`);
    }

    navigateEditResource(resource: ProblemFinderOverride): void {
        this.navigate(`${RouteName.OVERRIDES}/${resource.problemFinderOverrideId}/edit`);
    }

    navigateViewResource(resource: ProblemFinderOverride): void {
        this.navigate(`${RouteName.OVERRIDES}/${resource.problemFinderOverrideId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.OVERRIDES);
    }


    resourceName(): string {
        return 'Problem Finder Override';
    }

    async editResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, EditResourceConfiguration>> {
        let problemFinderRegistrations = await listProblemFinderRegistrations(webStageConfig, selectedRegion);
        return {
            // No custom identifiers here
            problemFinderOverrideId: {
                helperText: 'The Override ID',
                label: 'Override ID *',
                isIdentifier: true,
                editable: false
            },
            problemFinderId: {
                helperText: 'The problem finder this override is associated with.',
                label: 'Override Problem Finder ID *',
                componentType: EditResourceComponentType.SINGLE_SELECT_SEARCHABLE_LIST,
                acceptedValues: problemFinderRegistrations.map(v => {
                    return {label: v.problemFinderId, value: v.problemFinderId};
                }),
            },
            overrideScope: {
                helperText: 'The scope of the override',
                label: 'Override Scope *',
                validationRegex: new RegExp('^(?:(^NA$)|(^EU$)|(^AP$)|(^[a-zA-Z0-9-*]+/[a-zA-Z0-9-*]+/[a-zA-Z0-9-*]+$)|(^\\w{4}$)|(^[A-Z]{3}\\d$)|(^K[A-Z]{3}$)|(^[A-Z]{3}\\d{2,3}$)|(^\\*$))$'),
                componentType: EditResourceComponentType.INPUT
            },
            overrideScopeType: {
                helperText: 'The scope type of the override',
                label: 'Override Scope Type *',
                acceptedValues: [ScopeType.KSK, ScopeType.REGION, ScopeType.BUILDING].map(v => {
                    return {label: v, value: v};
                }),
                componentType: EditResourceComponentType.SELECT
            },
            overrideKey: {
                helperText: 'The override key. Must match key used in Problem Finder.',
                label: 'Override Key *',
                componentType: EditResourceComponentType.INPUT
            },
            overrideValue: {
                helperText: 'The override value.',
                label: 'Override Value *',
                componentType: EditResourceComponentType.INPUT
            },
        };
    }

    async listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<ProblemFinderOverride>>> {
        return {
            problemFinderOverrideId: {
                displayName: 'Override ID',
                componentType: ListResourceComponentType.TEXT,
                onClick: id => this.navigate(RouteName.OVERRIDES + `/${id}`),
                sortable: true
            },
            problemFinderId: {
                displayName: 'Problem Finder ID',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            overrideScope: {
                displayName: 'Scope',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            overrideKey: {
                displayName: 'Override Key',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            overrideValue: {
                displayName: 'Override Value',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
        };
    }


    initializeDefaultObject(): ProblemFinderOverride {
        return {
            problemFinderOverrideId: v4(),
            problemFinderId: undefined,
            overrideScope: undefined,
            overrideScopeType: undefined,
            overrideKey: undefined,
            overrideValue: undefined,
            updateTimeEpochMs: Date.now(),
            creationTimeEpochMs: Date.now(),
        };
    }


    getId(resource: ProblemFinderOverride): string {
        return resource.problemFinderOverrideId;
    }


}
