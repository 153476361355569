/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import cron from 'cron-validate';

export const cronExpressionToFrequency = (exp: string): { frequency: number, periodUnit: 'minute' | 'hour' | 'day' | 'week', withTime?: string } => {
    if (!exp) {
        throw new Error('Cron expression is not defined');
    }
    const cronCheck = cron(exp);
    if (cronCheck.isValid()) {
        const cronValues = cronCheck.getValue();
        const {seconds, minutes, hours, months, years, daysOfMonth, daysOfWeek} = cronValues;
        if (seconds !== undefined || years !== undefined || months !== '*' || daysOfWeek !== '*') {
            throw new Error('Cron expression contains unsupported fields for parsing frequency');
        } else {
            // Possible options for expression:

            // 1 minute - * * * * *
            // > 1 minute - x/2 * * * *

            // 1 hour - 0 * * * *
            // > 1 hour - 0 x/2 * * *

            // 1 day - 0 0 * * *
            // 1 day with time - 0 14 * * *

            // > 1 day - 0 0 x/2 * *
            // > 1 day with time - 0 14 x/2 * *

            // Minute unit
            if (minutes === '*' && hours === '*' && daysOfMonth === '*') {
                return {
                    frequency: 1,
                    periodUnit: 'minute'
                };
            } else if (isCronSlash(minutes) && hours === '*' && daysOfMonth === '*') {
                return {
                    frequency: getValueFromCronSlash(minutes),
                    periodUnit: 'minute'
                };

                // Hour unit
            } else if (minutes === '0' && hours === '*' && daysOfMonth === '*') {
                return {
                    frequency: 1,
                    periodUnit: 'hour'
                };
            } else if (minutes === '0' && isCronSlash(hours) && daysOfMonth === '*') {
                return {
                    frequency: getValueFromCronSlash(hours),
                    periodUnit: 'hour'
                };

                // Day unit
            } else if (!isNaN(Number(minutes)) && !isNaN(Number(hours)) && (daysOfMonth === '*' || isCronSlash(daysOfMonth))) {
                return {
                    frequency: daysOfMonth === '*' ? 1 : getValueFromCronSlash(daysOfMonth),
                    periodUnit: 'day',
                    withTime: `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`
                };
            } else {
                // Note, this does not mean the Cron expression is invalid, but we can only do so much to parse
                // a Cron expression into the input fields - trying my best here :shrug:
                throw new Error('Cron expression contains non-parseable fields');
            }
        }
    } else {
        throw new Error('Cron expression is invalid');
    }
};

const isCronSlash = (s: string) => {
    return s.length >= 3 && s[0] === '*' && s[1] === '/' && !isNaN(Number(s.slice(2, s.length)));
};

const getValueFromCronSlash = (s: string): number => {
    return Number(s.split('/')[1]);
};

/**
 * Validates cron input for 'every' fields i.e. every 2 hours.
 * The cron-validate library does not validate for min and max when dealing with these fields.
 * 
 * @param s the cron string to validate
 * @returns {string | null} cron's error message
 */
export const getCronError = (s: string): string | null => {
    if (!s) {
        // A schedule is not required to register a problem finder.
        return null;
    }
    const cleanedCron = s.split(' ').map((field: any) => {
        if (isCronSlash(field)) {
            return getValueFromCronSlash(field);
        } return field;
    }).join(' ');
    return !cron(cleanedCron).isValid() ? cron(cleanedCron).getError().join('\n') : null;
};
