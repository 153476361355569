/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import React from 'react';

const Page401 = () => {
    return (
        <Column width='100%' height='100%' alignmentHorizontal='start' spacingInset='400'>
            You are not authorized to access this application.
        </Column>
    );
};

export default Page401;
